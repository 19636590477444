import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import './SelfRatingForm.css'
import { server } from '../../api/apiservice';
import { useParams, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';  // Import toast
import 'react-toastify/dist/ReactToastify.css';  // Import styles
import { IoMdArrowRoundBack } from "react-icons/io";

const SelfRatingForm = () => {
    const [forms, setForms] = useState([]);
    const [selectedForm, setSelectedForm] = useState(null);
    const [ratings, setRatings] = useState({});
    const [employeeId, setEmployeeId] = useState(null);
    const [employeeName, setEmployeeName] = useState(null);
    const [employeeMail, setEmployeeMail] = useState(null);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [formAlreadySubmitted, setFormAlreadySubmitted] = useState(false);
    const [submittedForms, setSubmittedForms] = useState([]);
    const [selfRated, setSelfRated] = useState(false);
    const [managerRated, setManagerRated] = useState(false);
    const [ceoRated, setCeoRated] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();

    console.log("idddddddddddddddddddddd",id)
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const decodedToken = jwtDecode(token);
            setEmployeeId(decodedToken.id);
            setEmployeeName(decodedToken.name);
            setEmployeeMail(decodedToken.email);
        }

        // Fetch forms
        axios.get(`${server}admin/forms`)
            .then(response => {
                setForms(response.data);
 console.log("esponsedata",response.data);

                
                if (id) {
                    const selected = response.data.find(form => form._id === id);
                    setSelectedForm(selected);
                    
                }
            })
            
            .catch(error => {
                console.error('Error fetching forms:', error);
            });
 
        // Fetch ratings
        if (employeeId) {
            axios.get(`${server}ratings/${employeeId}`)
                .then(response => {
                    console.log('Ratings response:', response.data);
                    const { employeeRatings, managerRatings, ceoRatings } = response.data;

                    // Collect form IDs of submitted ratings
                    const submittedFormIds = [
                        ...employeeRatings.map(rating => rating.formId._id),
                        ...managerRatings.map(rating => rating.formId._id),
                        ...ceoRatings.map(rating => rating.formId._id),
                    ];
                    setSubmittedForms(submittedFormIds);

                    // Check if the current form is submitted
                    if (id && submittedFormIds.includes(id)) {
                        setFormAlreadySubmitted(true);
                    }

                    // Set rating statuses based on fetched ratings
                    const selfRating = employeeRatings.find(rating => rating.formId._id === id);
                console.log("esponsedata1",employeeRatings.formId._id);

                    setSelfRated(!!selfRating);
                    console.log("selfRatings:", employeeRatings, id);

                    const managerRating = managerRatings.find(rating => rating.formId._id === id);
                    setManagerRated(!!managerRating);
                    console.log("managerRatings:", managerRatings);

                    const ceoRating = ceoRatings.find(rating => rating.formId._id === id);
                    setCeoRated(!!ceoRating);
                    console.log("ceoRatings:", ceoRatings);
                })
                .catch(error => {
                    console.error('Error fetching ratings:', error);
                });
        }
    }, [id, employeeId]);

    const handleFormSelection = (formId) => {
        navigate(`/form/${formId}`);
    };

    const handleRatingChange = (questionIndex, value) => {
        setRatings(prevRatings => ({
            ...prevRatings,
            [questionIndex]: value,
        }));
    };

    const handleSubmit = () => {
        if (!employeeId) {
            console.error('Employee ID is null or undefined');
            return;
        }

        const allAnswered = selectedForm.questions.every((question, index) => {
            return ratings[index] !== undefined && ratings[index] !== '';
        });

        if (!allAnswered) {
            toast.error('Please answer all questions before submitting.', {
                position: "top-right",
                autoClose: 2000,
            });
            return;
        }

        const selfRatingData = {
            employeeId: employeeId,
            employeeName: employeeName,
            employeeMail: employeeMail,
            formId: selectedForm._id,
            raterRole: 'self',
            ratings: selectedForm.questions.map((question, index) => ({
                questionIndex: index,
                response: ratings[index] || ''
            }))
        };

        axios.post(`${server}userRoutes/submit-rating`, selfRatingData)
            .then(response => {
                console.log('Response from server:', response);
                toast.success('Rating submitted successfully!', {
                    position: "top-right",
                    autoClose: 2000,
                });
                setIsSubmitted(true);
                setSelfRated(true);
                console.log('Self rating submitted, selfRated set to true');

                setTimeout(() => {
                    navigate('/employee-dashboard');
                }, 2000);
            })
            .catch(error => {
                console.error('Error submitting rating:', error);
                toast.error("Form already submitted", {
                    position: "top-right",
                    autoClose: 2000,
                });
            });
    };

    console.log('selfRatedddddddddddddddd:', selfRated);
    console.log('Current form ID:', id);

    // Assuming you have a state to track if self-rating is completed
    const isSelfRated = (formId) => {
        // Logic to determine if the self-rating for the given formId is completed
        return submittedForms.includes(formId); // Adjust this logic based on your actual implementation
    };

    return (
        <div className="self-rating-form">
            {formAlreadySubmitted ? (
                <div>
                    <button onClick={() => navigate(-1)} className="back-button">
                        <IoMdArrowRoundBack />
                    </button>
                    <h4>Your Self rating form already submitted. Wait for the next form.</h4>
                </div>
            ) : isSubmitted ? (
                <h4>Thank you! Your rating has been submitted.</h4>
            ) : (
                <>
                    <h3>Select a Form to Rate Yourself</h3>
                    <div className="form-buttons-container">
                        {forms.slice().reverse().map(form => (
                            <button
                                key={form._id}
                                onClick={() => handleFormSelection(form._id)}
                                className={`form-title-button ${isSelfRated(form._id) ? 'submitted-form' : ''}`}
                            >
                                {form.title}
                                <div className="rating-status">
                                    <div
                                        className={`status-dot ${selfRated ? 'self-rated' : ''}`}
                                        data-status="Self Rating"
                                    />
                                    <div
                                        className={`status-dot ${managerRated ? 'manager-rated' : ''}`}
                                        data-status="Manager Rating"
                                    />
                                    <div
                                        className={`status-dot ${ceoRated ? 'ceo-rated' : ''}`}
                                        data-status="CEO Rating"
                                    />
                                </div>
                            </button>
                        ))}
                    </div>
                    {selectedForm && selectedForm.questions && (
                        <div className="form-details">
                            <h3 className='selected-form-title'>{selectedForm.title}</h3>
                            <div className="form-and-image-container">
                                <div className="form-content">
                                    {selectedForm.questions.map((question, index) => (
                                        <div key={index} className="form-fields">
                                            <label>{question.label}</label>
                                            {question.options.length > 0 ? (
                                                <select
                                                    className='form-selects'
                                                    value={ratings[index] || ''}
                                                    onChange={(e) => handleRatingChange(index, e.target.value)} >
                                                    <option value="" className='form-options'>Select an option</option>
                                                    {question.options.map((option, i) => (
                                                        <option key={i} value={option} className='form-options-option'>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </select>
                                            ) : (
                                                <input
                                                    type="text"
                                                    value={ratings[index] || ''}
                                                    onChange={(e) => handleRatingChange(index, e.target.value)}
                                                />
                                            )}
                                        </div>
                                    ))}
                                    <button onClick={handleSubmit}>Submit Rating</button>
                                </div>
                                <img src="/perform.jpg" alt="Description" className="form-image" />
                            </div>
                        </div>
                    )}
                </>
            )}
            <ToastContainer />
        </div>
    );
};

export default SelfRatingForm;









