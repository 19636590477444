import React, { useState, useEffect } from 'react';
import './MainDashboard.css';
import ApplyLeave from '../../leaveData/ApplyLeave';
import RequestAllowance from './RequestAllowance';
import axios from 'axios';
import { server } from '../../api/apiservice';
import LeaveHistory from '../../leaveData/LeaveHistory';
import LeaveCalendar from '../../leaveData/leaveCalender';
import NavigationBar from '../NavBar/NavigationBar';
import { FcLeave } from "react-icons/fc";
import Notifications from '../Notifications'
const MainDashboard = () => {
  const [isApplyLeaveOpen, setIsApplyLeaveOpen] = useState(false);
  const [isLeaveHistoryOpen, setIsLeaveHistoryOpen] = useState(false);
  const [users, setUserData] = useState([]);

  const [holidays, setHolidays] = useState([
    { date: "26 Jan", name: "Republic Day ", imageUrl: "republic.jpg" },
    { date: "8 Mar", name: "Maha Shivaratri", imageUrl: "shivratri.avif" },
    { date: "25 Mar", name: "Holi", imageUrl: "holi.avif" },
    { date: "9 Apr", name: "Ugadi", imageUrl: "ugadi.webp" },
    { date: "1 May", name: "Labour Day ", imageUrl: "labour.jpeg" },
    { date: "15 Aug", name: "Independence Day", imageUrl: "indi.jpg" },
    { date: "7 Sep", name: "Ganesh Chaturthi", imageUrl: "ganesh.jpeg" },
    { date: "2 Oct", name: "Gandhi Jayanti", imageUrl: "gandhi.jpg" },
    { date: "11 Oct", name: "Maha Navami", imageUrl: "navami.avif" },
    { date: "12 Oct", name: "Dusshera", imageUrl: "dus.jpeg" },
    {
      date: "1 Nov",
      name: "Kannada Rajyothsava / Diwali",
      imageUrl: "kan.jpeg",
    },
    { date: "5 Nov", name: "Balipadyami, Deepavali", imageUrl: "diwali.jpeg" },
    { date: "25 Dec", name: "Christmas", imageUrl: "christmas.jpg" },
  ]);

  const getGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 12) return "Good morning";
    if (hour < 17) return "Good afternoon";
    return "Good evening";
  };

  const toggleApplyLeave = () => {
    setIsApplyLeaveOpen(!isApplyLeaveOpen);
  };

  const toggleLeaveHistory = () => {
    setIsLeaveHistoryOpen(!isLeaveHistoryOpen);
  };


  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem("token");
      if (!token) return;
      try {
        const { data } = await axios.get(
          `${server}auth/dashboard`,
          {
            headers: { Authorization: `${token} `},
          }
        );
        const { name, role, dateOfBirth, joiningDate, profileImage } = data;
        console.log("Profile Image URL:", data.profileImage);
        console.log("URLllllllllll:", data);
        setUserData(data); // Assuming data contains user info
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    fetchUserData();
  }, []);

  return (
    <div className='Main-dashboar'>
    {/* <NavigationBar/> */}

      <h1>{getGreeting()}, {users.name}</h1>
      <div className="grid-layout">
        <div className='Main1' style={{ display: 'flex' }}>
          <div className="grid-item profile-dashboard">
            <img className="profile-avatar-empDashdoard"
              src={
                users.profileImage
                  ? `${server}${users.profileImage}`
                  : "profilemen.avif"
              }
              alt="Profile Avatar"
            />
            <h3>{users.name}</h3>

            <div className="leave-info">
              <div>Office Days: 24</div>
              <div>Attended Days: 21</div>
              <div>Absent Days: 3</div>
            </div>

          </div>
          <div style={{ display: "flex" }}>
            <div>
              <div style={{ justifyContent: 'space-around' }}>
                <div style={{ display: "flex" }}>
                  <div className="grid-item card-small">
                    <h3>Casual Leaves</h3>
                    <div className='numbersdata'>
                      <strong>1</strong>
                    </div>
                  </div>
                  <div className="grid-item card-small">
                    <h3>Sick Leaves</h3>
                    <div className='numbersdata'>
                      <strong>1</strong>
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div className="grid-item card-small">
                    <h3>Earned Leaves</h3>
                    <div className='numbersdata'>
                      <strong>1</strong>
                    </div>
                  </div>
                  <div className="grid-item card-small">
                    <h3>CompOf</h3>
                    <div className='numbersdata'>
                      <strong>0</strong>
                    </div>
                  </div>
                </div>

              </div>

              <div className="buttons">
                <button style={{width:"95%", display:"flex", justifyContent:"center", marginBottom:"opx"}} onClick={toggleApplyLeave}>Request Leave</button>

              </div>
            </div>

            <div >
              <div className="grid-item card-small-2">
                <h3 style={{margin:"0px"}}> Calender</h3>
                <LeaveCalendar />
              </div>
            </div>
          </div>

        </div> 

        <div className='Main2' style={{ display: 'flex' }}>
          <div className="grid-item card-medium" style={{ width: "330px", overflowY: 'auto', maxHeight: '400px' }}>
            <h3 style={{display:"fixed"}}>Holiday List</h3>
            
            <ul>
              {holidays.map((holiday, index) => (
                <li key={index} style={{ display: 'flex', alignItems: 'center', margin:"0px 0px 10px 0px"}}>
                  <img src={holiday.imageUrl} alt={holiday.name} style={{ width: '50px', marginRight: '10px' }} />
                  <span>{holiday.date} - {holiday.name}</span>
                </li>
              ))}
            </ul>
          </div>
          <div className="grid-item card-medium">
            <h3>Upcoming Events</h3>                   
            {/* <img src="upcoming.jpg" alt="upcoming" style={{width:"100%", height:"100%"}}/> */}
            {/* <Notifications /> */}
          </div>
          <div className="grid-item card-medium" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div onClick={toggleLeaveHistory} style={{ cursor: "pointer", display: "flex", flexDirection: "column", alignItems: "center" }}>
          {/* <FcLeave  style={{ fontSize: "110px",  marginLeft:"10px" ,color:"red"}} />  */}
          <img onClick={toggleLeaveHistory} src="LeaveHistory.png" alt="leave" style={{ width: "110px", height: "110px", cursor: "pointer" }} />

                    <p style={{ margin: "0px", color: "black", textAlign: "center" }}>Leave History</p>
                </div>

            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <img src="business.png" alt="attendance" style={{ width: "110px", height: "110px", cursor: "pointer" }} />
                <p style={{ margin: "0px", color: "black", textAlign: "center" }}>Attendance</p>
            </div>
          </div>
        </div>

      </div>

      <ApplyLeave isOpen={isApplyLeaveOpen} toggleApplyLeave={toggleApplyLeave} />
      <LeaveHistory isOpen={isLeaveHistoryOpen} toggLeaveHistory={toggleLeaveHistory} />
     
    </div>
  );
};

export default MainDashboard;