import React, { useState, useEffect } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import "./ManagerDashboard.css";
import { useNavigate, useParams } from "react-router-dom";
import SidebarAll from "../sidebar"; // Correct import for SidebarAll component
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { server } from "../../api/apiservice";
import HolidayOverlay from "../Calender/HolidayOverlay";
import Notifications from "../Notifications";
import MainDashboard from '../DashBoard/MainDashboard';
import NavigationBar from "../NavBar/NavigationBar";
import ApproveLeave from "../../leaveData/ApproveLeave";
import HierarchyChart from "../HierarchyChart/hierarchychart";



const ManagerDashboard = () => {
  const [forms, setForms] = useState([]);
  const [selectedFormId, setSelectedFormId] = useState(null); // Track expanded form
  const [expandedEmployeeIds, setExpandedEmployeeIds] = useState([]); // Track expanded employees
  const [answers, setAnswers] = useState({});
  const [employeeId, setEmployeeId] = useState('');
  const [employeeRatings, setEmployeeRatings] = useState([]);
  const [managerRatings, setManagerRatings] = useState([]); // New state for manager ratings
  const [feedbacks, setFeedbacks] = useState({}); // State for managing feedback
  const [submittedFeedbacks, setSubmittedFeedbacks] = useState({}); // Track submitted feedback
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState('Manager');
  const [showFormsSection, setShowFormsSection] = useState(false);
  const [users, setUsers] = useState([]);
  const [showUsers, setShowUsers] = useState(false);
  const [currentSection, setCurrentSection] = useState('');
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [averages, setAverages] = useState({});
  const [ceoRatings, setCeoRatings] = useState([]);
  const [topThreeUsers, setTopThreeUsers] = useState([]);
  const [activeSection, setActiveSection] = useState('dashboard');
  const [selfRating, setSelfRating] = useState({}); // New state for self-rating


  const [openModal, setOpenModal] = useState(false);
  const [searchName, setSearchName] = useState('');
  const [holidays, setHolidays] = useState([
    { date: '26 Jan', name: 'Republic Day ', imageUrl: 'republic.jpg' },
    { date: '8 Mar', name: 'Maha Shivaratri', imageUrl: 'shivratri.avif' },
    { date: '25 Mar', name: 'Holi', imageUrl: 'holi.avif' },
    { date: '9 Apr', name: 'Ugadi', imageUrl: 'ugadi.webp' },
    { date: '1 May', name: 'Labour Day ', imageUrl: 'labour.jpeg' },
    { date: '15 Aug', name: 'Independence Day', imageUrl: 'indi.jpg' },
    { date: '7 Sep', name: 'Ganesh Chaturthi', imageUrl: 'ganesh.jpeg' },
    { date: '2 Oct', name: 'Gandhi Jayanti', imageUrl: 'gandhi.jpg' },
    { date: '11 Oct', name: 'Maha Navami', imageUrl: 'navami.avif' },
    { date: '12 Oct', name: 'Dusshera', imageUrl: 'dus.jpeg' },
    { date: '1 Nov', name: 'Kannada Rajyothsava / Diwali', imageUrl: 'kan.jpeg' },
    { date: '5 Nov', name: 'Balipadyami, Deepavali', imageUrl: 'diwali.jpeg' },
    { date: '25 Dec', name: 'Christmas', imageUrl: 'christmas.jpg' },
    { date: '26 Jan', name: 'Republic Day ', imageUrl: 'republic.jpg' },
    { date: '8 Mar', name: 'Maha Shivaratri', imageUrl: 'shivratri.avif' },
    { date: '25 Mar', name: 'Holi', imageUrl: 'holi.avif' },
    { date: '9 Apr', name: 'Ugadi', imageUrl: 'ugadi.webp' },
    { date: '1 May', name: 'Labour Day ', imageUrl: 'labour.jpeg' },
    { date: '15 Aug', name: 'Independence Day', imageUrl: 'indi.jpg' },
    { date: '7 Sep', name: 'Ganesh Chaturthi', imageUrl: 'ganesh.jpeg' },
    { date: '2 Oct', name: 'Gandhi Jayanti', imageUrl: 'gandhi.jpg' },
    { date: '11 Oct', name: 'Maha Navami', imageUrl: 'navami.avif' },
    { date: '12 Oct', name: 'Dusshera', imageUrl: 'dus.jpeg' },
    { date: '1 Nov', name: 'Kannada Rajyothsava / Diwali', imageUrl: 'kan.jpeg' },
    { date: '5 Nov', name: 'Balipadyami, Deepavali', imageUrl: 'diwali.jpeg' },
    { date: '25 Dec', name: 'Christmas', imageUrl: 'christmas.jpg' },
  ]);

  
console.log("API Response employeeRatings ",employeeRatings);


  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [employeeName, setEmployeeName] = useState(null);
  const [employeeMail, setEmployeeMail] = useState(null);
  const { id } = useParams();
  const [selectedForm, setSelectedForm] = useState(null);
  const [formAlreadySubmitted, setFormAlreadySubmitted] = useState(false);

  const handleFeedbackCardClick = (feedback) => {
    setSelectedFeedback(feedback); // Set the selected feedback
  };

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      const decodedToken = jwtDecode(token);
      setEmployeeId(decodedToken.id);
      setEmployeeName(decodedToken.name);
      setEmployeeMail(decodedToken.email);
    }

    axios.get(`${server}admin/forms`)
      .then(response => {
        setForms(response.data);
        console.log("response.data", response.data);

        // Automatically select the form if an ID is present in the URL
        if (id) {
          const selected = response.data.find(form => form._id === id);
          setSelectedForm(selected);
        }
      })
      .catch(error => {
        console.error('Error fetching forms:', error);
      });

    // Check if the employee has already submitted a form
    if (employeeId) {
      axios.get(`${server}ratings/${employeeId}`)
        .then(response => {
          if (response.data.some(rating => rating.formId === id)) {
            setFormAlreadySubmitted(true);
          }
        })
        .catch(error => {
          console.error('Error checking submission status:', error);
        });
    }

  }, [id, employeeId]);





  const getOrdinalSuffix = (index) => {
    const suffixes = ["st", "nd", "rd"];
    const remainder = index % 10;
    const suffix = remainder > 0 && remainder < 4 && ![11, 12, 13].includes(index) ? suffixes[remainder - 1] : "th";
    return `${index}${suffix}`;
  };


  const [isHolidayOverlayVisible, setIsHolidayOverlayVisible] = useState(false);
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); 

  const handleHolidaysClick = () => {
    setIsHolidayOverlayVisible(true);
  };

  const closeHolidayOverlay = () => {
    setIsHolidayOverlayVisible(false);
  };

  const handleDashboardClick = () => {
    setActiveSection('dashboard');
    setShowFormsSection(false);
    setShowUsers(false);
  };

  const handleApprovalClick = () => {
    setActiveSection('approval');
    setShowFormsSection(false);
   
    
  };


  const handleEmployeeExpansionAndModal = (employeeId) => {
    toggleEmployeeExpansion(employeeId); // Toggle the employee expansion
    // handleExpandedClick(); // Open/close the modal
    setOpenModal(true);
  };
  const closeExpandedClick = () => {
    setOpenModal(false); // Close the modal
    setExpandedEmployeeIds([]); // Reset the expanded employee list
  };

  const saveOverallAverage = async (employeeId, employeeName, overallAverage) => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(
        `${server}userRoutes/save-overall-average`,
        { employeeId, employeeName, overallAverage },
        { headers: { Authorization: `${token}` } }
      );
      console.log('Overall average saved successfully');
    } catch (error) {
      console.error('Error saving overall average:', error);
    }
  };

  // Check authentication on component mount
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/'); // Redirect to login if not authenticated
    }
  }, [navigate]);


  // useEffect(() => {
  //   if (userRole === 'Manager') {
  //     setShowFormsSection(true);
  //   }
  // }, [userRole]);



  useEffect(() => {
    const token = localStorage.getItem('token');

    axios.get(`${server}ceo-ratings/employee-ratings`, {
      headers: { Authorization: `${token}` },
    })
      .then(response => {
        const { employeeRatings = [], managerRatings = [], ceoRatings = [] } = response.data;

        // Check if employeeRatings array is valid
        setEmployeeRatings(employeeRatings);
        setManagerRatings(managerRatings);
        setCeoRatings(ceoRatings);

        // Calculate averages, making sure objects exist and values are valid
        const calculatedAverages = employeeRatings.reduce((acc, rating) => {
          if (!rating || !rating.ratings) return acc;  // Check if rating exists

          const numericValues = rating.ratings.map(item => {
            if (typeof item.response === 'string') {
              const match = item.response.match(/\d+/);
              return match ? parseInt(match[0], 10) : 0;
            }
            return 0;
          });

          const average = numericValues.length > 0
            ? numericValues.reduce((a, b) => a + b, 0) / numericValues.length
            : 0;

          if (rating.employeeId && rating.employeeId._id) {
            acc[rating.employeeId._id] = average.toFixed(2);
          }
          return acc;
        }, {});

        // Similarly, calculate manager and CEO averages
        const calculatedManagerAverages = managerRatings.reduce((acc, rating) => {
          if (!rating || !rating.answers) return acc;

          const numericValues = rating.answers.map(item => {
            const match = item.response ? item.response.match(/\d+/) : null;
            return match ? parseInt(match[0], 10) : 0;
          });

          const average = numericValues.length > 0
            ? numericValues.reduce((a, b) => a + b, 0) / numericValues.length
            : 0;

          if (rating.employeeId && rating.employeeId._id) {
            acc[rating.employeeId._id] = average.toFixed(2);
          }
          return acc;
        }, {});

        const calculatedCeoAverages = ceoRatings.reduce((acc, rating) => {
          if (!rating || !rating.answers) return acc;

          const numericValues = rating.answers.map(item => {
            const match = item.response ? item.response.match(/\d+/) : null;
            return match ? parseInt(match[0], 10) : 0;
          });

          const average = numericValues.length > 0
            ? numericValues.reduce((a, b) => a + b, 0) / numericValues.length
            : 0;

          if (rating.employeeId && rating.employeeId._id) {
            acc[rating.employeeId._id] = average.toFixed(2);
          }
          return acc;
        }, {});

        setAverages({
          employees: calculatedAverages,
          managers: calculatedManagerAverages,
          ceos: calculatedCeoAverages,
        });
      })
      .catch(error => {
        console.error('Error fetching ratings:', error);
      });
  }, []);



  const calculateOverallAverage = (employeeId, employeeName) => {

    const employeeAvg = parseFloat(averages.employees?.[employeeId] || 0);
    const managerAvg = parseFloat(averages.managers?.[employeeId] || 0);
    const ceoAvg = parseFloat(averages.ceos?.[employeeId] || 0);

    const overallAverage = ((employeeAvg + managerAvg + ceoAvg) / 3).toFixed(2);

    saveOverallAverage(employeeId, employeeName, overallAverage);

    console.log("Calculating average for", employeeName, employeeId);



    return overallAverage;
  };

  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen); // toggles the filter open/close
  };

  const fetchForms = async (filter) => {
    try {
      setLoading(true);
      const response = await axios.get(`${server}admin/forms-by-month`, {
        params: { filter }
      });
      console.log("zzzzzzzzzzzz", response);

      setForms(response.data.data); // Update forms with the API response
      setError("");
    } catch (err) {
      setForms([]);
      setError(err.response?.data?.message || "Error fetching forms");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (selectedFilter) {
      fetchForms(selectedFilter);
    }
  }, [selectedFilter]);



  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      setEmployeeId(decodedToken.id);
    }

    // Fetch available forms
    axios.get(`${server}admin/forms`)
      .then(response => {
        setForms(response.data);
      })
      .catch(error => {
        console.error('Error fetching forms:', error);
      });
  }, []);



  useEffect(() => {
    const token = localStorage.getItem('token');
    console.log('Token:', token);

    const fetchRatings = async (searchName) => {
      try {
        const response = await axios.get(`${server}manager-ratings/employee-ratings`, {
          // headers: { Authorization: Bearer ${token} }
          headers: { Authorization: `${token}` },
          params: { name: searchName },
        });

        setEmployeeRatings(response.data.employeeRatings);
        console.log("API response:", response.data);

        setManagerRatings(response.data.managerRatings);
        console.log("2222222222",response.data.employeeRatings);
        console.log("2222222222111",response.data.managerRatings);
        
      } catch (err) {
        setError(err.message);
        console.error('Error fetching ratings:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchRatings(searchName);
  }, [searchName]);

  const handleSearchChange = (e) => {
    setSearchName(e.target.value); // Update search input value
  };

  const fetchUsers = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${server}userRoutes/users`, {
        headers: { Authorization: `${token} `},
      });
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };


  const toggleFormExpansion = (formId) => {
    setSelectedFormId(prevState => prevState === formId ? null : formId); // Toggle form expansion
    setExpandedEmployeeIds([]); // Reset employee expansion when changing forms
  };

  const toggleEmployeeExpansion = (employeeId) => {
    setExpandedEmployeeIds(prevState =>
      prevState.includes(employeeId)
        ? prevState.filter(id => id !== employeeId) // Collapse if already expanded
        : [...prevState, employeeId] // Expand if not already expanded
    );
  };

  const handleRatingChange = (question, value, employeeId) => {
    setAnswers(prevAnswers => ({
      ...prevAnswers,
      [employeeId]: {
        ...prevAnswers[employeeId],
        [question]: value,
      }
    }));
  };

  const handleFeedbackChange = (employeeId, value) => {
    setFeedbacks(prevFeedbacks => ({
      ...prevFeedbacks,
      [employeeId]: value
    }));
  };


  const handleSubmit = async (selectedEmployeeId) => {
    const token = localStorage.getItem('token');
    try {
      // Find the selected form and map the answers based on the manager's input
      const selectedForm = forms.find(form => form._id === selectedFormId);
      const managerRatings = selectedForm.questions.map((question, index) => ({
        questionIndex: index,
        response: answers[selectedEmployeeId]?.[question.label] || '' // Get the manager's response
      }));

      // Check if all questions have been answered
      if (managerRatings.some(rating => rating.response === '')) {
        toast.error('Please provide responses for all questions before submitting.', {
          position: "top-right",
          autoClose: 2000, // Toast will auto close in 2 seconds
        });
        // alert('Please provide responses for all questions before submitting.');
        return;
      }


      // Check if feedback is provided
      // if (!feedbacks[selectedEmployeeId] || feedbacks[selectedEmployeeId].trim() === '') {
      //   toast.error('Please provide feedback before submitting.', {
      //     position: "top-right",
      //     autoClose: 2000, // Toast will auto close in 2 seconds
      //   });
      //   return;
      // }

      const managerRatingData = {
        employeeId: selectedEmployeeId,
        formId: selectedFormId,
        answers: managerRatings,
        feedback: feedbacks[selectedEmployeeId] || ''
      };

      const response = await axios.post(`${server}manager-ratings/submit-rating-feedback-manager`, managerRatingData, {
        headers: { Authorization: `${token}` },
      });

      // Update the manager ratings after a successful submission
      setManagerRatings(prevRatings => [...prevRatings, response.data.managerRating]);

      //alert('Rating submitted successfully!');
      toast.success('Rating submitted successfully!', {
        position: "top-right",
        autoClose: 2000, // Toast will auto close in 3 seconds
      });


      // Hide or reset the form
      setSelectedFormId(null);  // Assuming you use selectedFormId to control form visibility
      setAnswers({});           // Reset form answers
      setFeedbacks({});         // Reset feedbacks


      setTimeout(() => {
        window.location.reload();
      }, 1000);


    } catch (error) {
      console.error('Error submitting rating:', error);
      // alert('Error submitting rating.');
      toast.error('Error submitting rating.', {
        position: "top-right",
        autoClose: 2000, // Toast will auto close in 3 seconds
      });

    }
  };

  const handleShowUsers = () => {
    setShowUsers(true);
    setShowFormsSection(false);
    setActiveSection(false)
    if (!showUsers) {
      fetchUsers();
    }
  };

  const onFormSelect = () => {
    setShowFormsSection(true);
    setShowUsers(false);
    setActiveSection(false)
  };

  // const handleSignout = () => {
  //   localStorage.removeItem('token');
  //   navigate('/');
  // };

  const handleSignout = () => {
    toast(<CustomLogoutToast />);
  };

  const CustomLogoutToast = ({ closeToast }) => (
    <div>
      <p style={{ color: "white" }}>Are you sure you want to Sign out?</p>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <button
          onClick={() => {
            localStorage.removeItem("token");
            toast.success("Successfully logged out!");
            navigate("/"); // navigate after logging out
            closeToast(); // close the toast
          }}
          style={{ borderRadius: "20px", cursor: "pointer" }}
        >
          Yes
        </button>
        <button 
          onClick={closeToast} 
          style={{ borderRadius: "20px", cursor: "pointer" }}
        >
          Cancel
        </button>
      </div>  
    </div>
  );

  // I am adding Fetch top 3 highest rated employees
  useEffect(() => {
    if (activeSection === 'topRated') {
      fetchTopThreeUsers();
    }
  }, [activeSection]);

  const fetchTopThreeUsers = async () => {
    try {
      const response = await axios.get(`${server}userRoutes/topThreeRatings`);
      console.log("responseresponse", response.data.ratings);

      setTopThreeUsers(response.data.ratings);
      console.log('responseeeeeeee.data.ratingsssssssssssssss', response.data.ratings);
    } catch (error) {
      console.error('Error fetching top 3 rated users:', error);
    }
  };

  const handleTopRatedClick = () => {
    setActiveSection('topRated');

    setShowFormsSection(false);
    setShowUsers(false);
  };

  const getRowColor = (employeeRating, managerRating, ceoRating) => {
    console.log('Ratings:', { employeeRating, managerRating, ceoRating });

    // Helper function to safely parse numeric rating
    const parseRating = (rating) => {
      if (typeof rating !== 'string') return 0;
      const match = rating.match(/\d+/);
      return match ? parseInt(match[0], 10) : 0;
    };

    const numericEmployeeRating = parseRating(employeeRating);
    const numericManagerRating = parseRating(managerRating);
    const numericCeoRating = parseRating(ceoRating);

    console.log('Numeric Ratings:', { numericEmployeeRating, numericManagerRating, numericCeoRating });

    let color;
    if (ceoRating && ceoRating !== 'N/A') {
      // CEO has rated
      if (numericEmployeeRating > numericManagerRating && numericEmployeeRating > numericCeoRating) {
        color = '#f64747';
      } else if (numericEmployeeRating < numericManagerRating || numericEmployeeRating < numericCeoRating) {
        color = '#00b5cc';
      } else {
        color = '#50C878';
      }
    } else {
      // CEO hasn't rated yet
      if (numericEmployeeRating > numericManagerRating) {
        color = '#f64747';
      } else if (numericEmployeeRating < numericManagerRating) {
        color = '#00b5cc';
      } else {
        color = '#50C878';
      }
    }

    console.log('Calculated color:', color);
    return color;
  };




  const handleSelfRatingSubmit = async (selectedFormId) => {
    const token = localStorage.getItem('token');

    // Validate that there are answers before submitting
    if (!Object.keys(selfRating).length) {
      toast.error('Please provide ratings before submitting.', {
        position: "top-right",
        autoClose: 2000,
      });
      return;
    }

    // Find the selected form
    const selectedForm = forms.find(form => form._id === selectedFormId);
    if (!selectedForm) {
      toast.error('Form not found.', {
        position: "top-right",
        autoClose: 2000,
      });
      return;
    }

    // Map answers to match the expected format
    const ratings = selectedForm.questions.map((question, index) => ({
      questionIndex: index,
      response: selfRating[question.label] || ''
    }));

    const requestBody = {
      employeeId: employeeId,
      formId: selectedFormId,
      ratings: ratings,
      raterRole: userRole,
    }

    try {
      const response = await axios.post(
        `${server}manager-ratings/manager-selfrating`,
        requestBody,
        {
          headers: { Authorization: `${token}` }
        }
      );
      console.log("12345678",response)

      toast.success('Self-rating submitted successfully!', {
        position: "top-right",
        autoClose: 2000,
      });

      // Reset form after successful submission
      setSelfRating({});

      // Optional: Refresh data
      setTimeout(() => {
        window.location.reload();
      }, 2000);

    } catch (error) {
      console.error('Error submitting self-rating:', error);
      toast.error(error.response?.data?.message || 'Error submitting self-rating.', {
        position: "top-right",
        autoClose: 2000,
      });
    }
  };

  const [anonymousFeedbacks, setAnonymousFeedbacks] = useState([]);
  const [showFeedbacks, setShowFeedbacks] = useState(false);
  console.log("1111111111111111111",anonymousFeedbacks);

  const handleFeedbackClick = () => {
    setActiveSection('feedback');
    setShowFormsSection(false);
    setShowUsers(false);
    fetchAnonymousFeedbacks();
  };

  const fetchAnonymousFeedbacks = async () => {
    try {
      const token = localStorage.getItem('token');
      const managerId = employeeId; // Assuming employeeId is the manager's ID
      const response = await axios.post(`${server}userRoutes/feedbackdata`, {
        manager_id: managerId // Pass the manager ID in the request body
      }, {
        headers: { Authorization: `${token} `}
      });
      console.log("API Response:", response.data);
      
      setAnonymousFeedbacks(response.data || []);
    } catch (error) {
      console.error('Error fetching feedbacks:', error);
      toast.error('Error fetching feedbacks');
    }
  };

  useEffect(() => {
    console.log("Current activeSection:", activeSection);
    console.log("Anonymous Feedbacks:", anonymousFeedbacks);
  }, [activeSection, anonymousFeedbacks]);

  // Function to handle mouse enter
  const handleMouseEnter = () => {
    setIsSidebarOpen(true); // Open sidebar on hover
  };

  // Function to handle mouse leave
  const handleMouseLeave = () => {
    setIsSidebarOpen(false); 
  };

  const RatingDots = ({ employeeRating, managerRating, ceoRating }) => {
    const getDotColor = (rating) => {
      if (rating) return 'green'; // Completed
      return 'gray'; // Not completed
    };

    return (
      <div style={{ display: 'flex', gap: '5px' }}>
        <div style={{ width: '10px', height: '10px', borderRadius: '50%', backgroundColor: getDotColor(employeeRating) }} />
        <div style={{ width: '10px', height: '10px', borderRadius: '50%', backgroundColor: getDotColor(managerRating) }} />
        <div style={{ width: '10px', height: '10px', borderRadius: '50%', backgroundColor: getDotColor(ceoRating) }} />
      </div>
    );
  };

  return (
    <>
    
    {/* <div className={`manager ${isSidebarOpen ? "dashboard-section sidebar-expanded" : ""} `}> */}
    <div 
        className={`manager ${isSidebarOpen ? "dashboard-section sidebar-expanded" : ""}`} 
        
    >
      <NavigationBar />
      <SidebarAll
        onDashboard={handleDashboardClick}
        onApproval={handleApprovalClick}
        onFormSelect={onFormSelect}
        onSignout={handleSignout}
        onShowUsers={handleShowUsers}
        onTopRated={handleTopRatedClick}
        onHolidays={handleHolidaysClick}
        userRole={userRole}
        onFeedback={handleFeedbackClick}
        onToggleSidebar={(isOpen) => setIsSidebarOpen(isOpen)} 
        onMouseEnter={handleMouseEnter} 
        onMouseLeave={handleMouseLeave} 
      />

      {/* Add MainDashboard component when dashboard is active */}
      {/* {activeSection === 'dashboard' && <MainDashboard />} */}
      <div>
      {activeSection==='dashboard' &&(
        <MainDashboard />
      )}
      </div>
      <div>
      {activeSection==='approval' &&(
        <ApproveLeave />
      )}
      </div>

      <div>
      {showFormsSection && (
        <div className="manager-dashboard" style={{marginTop:"100px"}}>
          <div>
            <div className='employeesContainer'>
              <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                <div style={{ width: "100%" }}><h2>Select a Form to View Employees</h2></div>
                <div className='btnCntner'>
                  {isFilterOpen && (
                    <div>
                      <div className='filterButtons'>
                        <button
                          style={{
                            background: selectedFilter === "this-month" ? "#517394" : "#2c3e50",
                            marginRight: "10px",
                            // marginLeft: "10px",
                            // width: "140px",
                            // height: "40px"
                          }}
                          onClick={() => setSelectedFilter("this-month")}
                          className='filterButtonsin'
                        >
                          This Month
                        </button>
                        <button
                          style={{
                            background: selectedFilter === "past-3-months" ? "#517394" : "#2c3e50",
                            marginRight: "10px",
                            // width: "140px",
                            // height: "40px"

                          }}
                          className='filterButtonsin'
                          onClick={() => setSelectedFilter("past-3-months")}
                        >
                          Past 3 Months
                        </button>
                        <button
                          style={{
                            background: selectedFilter === "past-6-months" ? "#517394" : "#2c3e50",
                            marginRight: "10px",
                            // width: "140px",
                            // height: "40px"
                          }}
                          className='filterButtonsin'

                          onClick={() => setSelectedFilter("past-6-months")}
                        >
                          Past 6 Months
                        </button>
                        <button
                          style={{
                            background: selectedFilter === "current-year" ? "#517394" : "#2c3e50",
                            // width: "140px",
                            // height: "40px"
                          }}
                          className='filterButtonsin'

                          onClick={() => setSelectedFilter("current-year")}
                        >
                          This Year
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                <div className='filterIcondiv'>
                  <i style={{ cursor: "pointer", fontSize: "1.5rem" }} class="fa-solid fa-filter" onClick={toggleFilter}></i>
                </div>
              </div>
            </div>
            {forms.map(form => (
              <div key={form._id}>
                <div class="card-container">
                  <div onClick={() => toggleFormExpansion(form._id)} style={{ display: "flex", marginBottom: "10px", color: "white", background: "#2c3e50", width: "fit-content", padding: "27px 15px", borderRadius: "10px", cursor: "pointer" }}>
                    <div style={{ display: "flex" }}>
                      {form.title}
                    </div>
                  </div>
                </div>
                {selectedFormId === form._id && (
                  <div>
                    <div style={{ display: "flex", position: "relative" }}>
                      <div>
                        <h3>Employees who have rated themselves:</h3>
                      </div>
                      <div className='searchInput' style={{ width: "40%", marginTop: "5px", position: "absolute", right: "0" }}>
                        <input type="text" placeholder='search by name' value={searchName} onChange={handleSearchChange} />
                        <i class="fa-solid fa-magnifying-glass"></i>
                      </div>
                    </div>
                    
                    {employeeRatings
                      .filter((rating) => rating?.formId?._id === form._id && rating?.employeeId?._id)
                      // console.log(" Api responce Current Form ID:", form._id)

                      .length > 0 ? (
                      <div style={{ paddingBottom: "30px" }}>
                        <table className="rating-table" >
                          <thead>
                            <tr>
                              <th>Sl No.</th>
                              <th>Name</th>
                              <th>Overall Avg</th>
                              <th>Action</th>
                              <th>Status</th>
                              <th>Check</th>
                            </tr>
                          </thead>
                          <tbody>
                            
                            {employeeRatings
                              .filter((rating) => rating?.formId?._id === form._id && rating?.employeeId?._id)
                              .map((rating, index) => {
                                // Check if manager and CEO have rated this employee
                                const managerRating = managerRatings.find(mRating => mRating.employeeId._id === rating.employeeId._id);
                                const ceoRating = ceoRatings.find(cRating => cRating.employeeId._id === rating.employeeId._id);

                                return (
                                  <tr key={rating.employeeId._id}>
                                    <td>{index + 1}</td>
                                    <td>{rating.employeeId.name || 'Unknown Employee'}</td>
                                    <td>{calculateOverallAverage(rating.employeeId._id, rating.employeeId.name)}</td>
                                    <td>
                                      <RatingDots 
                                        employeeRating={rating.ratings.length > 0} // Assuming ratings exist means completed
                                        managerRating={managerRating ? managerRating.answers.length > 0 : false} // Check if manager rated
                                        ceoRating={ceoRating ? ceoRating.answers.length > 0 : false} // Check if CEO rated
                                      />
                                    </td>
                                    <td>
                                      <button
                                        style={{ background: "#2c3e50" }}
                                        onClick={() => handleEmployeeExpansionAndModal(rating.employeeId._id)}
                                      >
                                        open
                                      </button>
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                      {managerRating && (
                                        <span style={{ 
                                          color: '#2c3e50', 
                                          fontSize: '20px',
                                          fontWeight: 'bold'
                                        }}>
                                          ✓
                                        </span>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>

                        </table>

                        {openModal && (
                          <div className="expanded-modal-overlay" onClick={closeExpandedClick}>
                            <div className='expanded-table-modal' onClick={(e) => e.stopPropagation()}>
                              <button style={{ background: "red" }} onClick={closeExpandedClick}>
                                Close
                              </button>
                              {employeeRatings
                                .filter((rating) =>
                                  expandedEmployeeIds.includes(rating?.employeeId?._id) &&
                                  rating?.formId?._id === selectedFormId) 
                                .map((rating) => (
                                  <div className="rating-details" key={rating?.employeeId?._id}>
                                    <h4>Ratings Summary for {rating?.employeeId?.name}</h4>
                                    <table className="ratings-table">
                                      <thead>
                                        <tr>
                                          <th>
                                            Employee's Ratings
                                            <br />
                                            Avg:{" "}
                                            {averages?.employees?.[
                                              rating?.employeeId?._id
                                            ] || "N/A"}
                                          </th>
                                          <th>
                                            Manager's Ratings
                                            <br />
                                            Avg:{" "}
                                            {averages?.managers?.[
                                              rating?.employeeId?._id
                                            ] || "N/A"}
                                          </th>
                                          <th>
                                            CEO's Ratings
                                            <br />
                                            Avg:{" "}
                                            {averages?.ceos?.[
                                              rating?.employeeId?._id
                                            ] || "N/A"}
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {rating?.formId?.questions?.map(
                                          (question, index) => {
                                            const employeeRating =
                                              rating?.ratings?.find(
                                                (r) => r.questionIndex === index
                                              )?.response || "N/A";
                                            const managerRating = managerRatings
                                              .filter(
                                                (mRating) =>
                                                  mRating?.employeeId?._id ===
                                                  rating?.employeeId?._id &&
                                                  mRating?.formId?._id ===
                                                  form?._id
                                              )
                                              .map(
                                                (mRating) =>
                                                  mRating?.answers?.find(
                                                    (a) =>
                                                      a.questionIndex === index
                                                  )?.response || "N/A"
                                              )[0];
                                            const ceoRating = ceoRatings
                                              .filter(
                                                (cRating) =>
                                                  cRating?.employeeId?._id ===
                                                  rating?.employeeId?._id &&
                                                  cRating?.formId?._id ===
                                                  form?._id
                                              )
                                              .map(
                                                (cRating) =>
                                                  cRating?.answers?.find(
                                                    (a) =>
                                                      a.questionIndex === index
                                                  )?.response || "N/A"
                                              )[0];

                                            const rowColor = getRowColor(
                                              employeeRating,
                                              managerRating,
                                              ceoRating
                                            );

                                            return (
                                              <tr key={index}>
                                                <td
                                                  style={{
                                                    backgroundColor: rowColor,
                                                  }}
                                                >
                                                  <div className="question">
                                                    {question.label}
                                                  </div>
                                                  <div className="response">
                                                    {employeeRating}
                                                  </div>
                                                </td>
                                                <td
                                                  style={{
                                                    backgroundColor: rowColor,
                                                  }}
                                                >
                                                  <div className="question">
                                                    {question.label}
                                                  </div>
                                                  <div className="response">
                                                    {managerRating}
                                                  </div>
                                                </td>
                                                <td
                                                  style={{
                                                    backgroundColor: rowColor,
                                                  }}
                                                >
                                                  <div className="question">
                                                    {question.label}
                                                  </div>
                                                  <div className="response">
                                                    {ceoRating}
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                      </tbody>
                                    </table>

                                    {/* Optional: Conditionally show the rating input if no CEO rating */}
                                    {!ceoRatings.some(cRating =>
                                      cRating?.employeeId?._id === rating?.employeeId?._id &&
                                      cRating?.formId?._id === selectedFormId) && (
                                        <>
                                          <h3>Rate The Employee:</h3>
                                          {forms
                                            .find(f => f?._id === selectedFormId)
                                            ?.questions?.map((question, index) => (
                                              <div key={index}>
                                                <label>{question?.label}</label>
                                                {question?.options && question?.options.length > 0 ? (
                                                  <select style={{ width: "50%" }}
                                                    onChange={(e) => handleRatingChange(question?.label, e.target.value, rating?.employeeId?._id)}
                                                    value={answers?.[rating?.employeeId?._id]?.[question?.label] || ''}
                                                  >
                                                    <option value="">Select an option</option>
                                                    {question.options.map((option, i) => (
                                                      <option key={i} value={option}>{option}</option>
                                                    ))}
                                                  </select>
                                                ) : (
                                                  <input
                                                    type="text"
                                                    onChange={(e) => handleRatingChange(question?.label, e.target.value, rating?.employeeId?._id)}
                                                    value={answers?.[rating?.employeeId?._id]?.[question?.label] || ''}
                                                  />
                                                )}
                                              </div>
                                            ))
                                          }
                                        </>
                                      )}

                                    <h4>Give Feedback</h4>
                                    <textarea style={{ width: "50%" }}
                                      value={feedbacks?.[rating?.employeeId?._id] || ''}
                                      onChange={(e) => handleFeedbackChange(rating?.employeeId?._id, e.target.value)}
                                      placeholder="Enter your feedback here"
                                    />
                                    <div>
                                      <button style={{ backgroundColor: "#2c3e50", marginTop: "20px", width: "20%" }} onClick={() => handleSubmit(rating?.employeeId?._id)}>Submit</button>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        )}

                      </div>
                    ) : (
                      <p>No employees have rated themselves.</p>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
      </div>
      <div className="all-users-data12" style={{ marginTop:"100px"}}>
        {showUsers && (
          <table className="users-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Role</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <React.Fragment key={user._id}>
                  <tr>
                    <td style={{ cursor: 'pointer', color: 'blue' }}>
                      {user.name}
                    </td>
                    <td>{user.email}</td>
                    <td>{user.role}</td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        )}
      </div>
      {activeSection === 'topRated' && (
        <div className='managermain'>
          <div className="overview-cards ">
            <div className="card">
              <h1 style={{ textAlign: 'center', marginBottom: '30px' }}>Top Rated Employees</h1>
              <div className="top-three">
                <div className="top-three-container" >  
                  {topThreeUsers.map((user, index) => (
                    <div
                      key={user._id}
                      className={`top-user-card top-user-${index + 1}`}
                    >
                      <img
                        src={
                          user.profileImage
                            ? `${server}${user.profileImage}`
                            : "profilemen.avif"
                        }
                        alt={user.employeeName}
                        className="top-user-avatar"
                      />
                      <div className="top-user-details">
                        <p>{user.employeeName}</p>
                        <p><b>{getOrdinalSuffix(index + 1)}</b></p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Holiday Overlay Component */}
      <HolidayOverlay
        holidays={holidays}
        isVisible={isHolidayOverlayVisible}
        onClose={closeHolidayOverlay}
      />
      <ToastContainer />

      {showFormsSection && (
        <div className="manager-dashboard" style={{ marginTop: "100px" }}>
          <h2>Manager Self Rating</h2>
          {forms.map(form => (
            <div key={form._id}>
              <div className="card-container">
                <div onClick={() => toggleFormExpansion(form._id)} style={{ display: "flex", marginBottom: "10px", color: "white", background: "#2c3e50", width: "fit-content", padding: "27px 15px", borderRadius: "10px", cursor: "pointer" }}>
                  <div style={{ display: "flex" }}>
                    {form.title}
                  </div>
                </div>       
              </div>
              {selectedFormId === form._id && (
                <div style={{marginLeft:"30px"}}>
                  <div style={{ display: "flex", position: "relative" }}>
                    <div>
                      <h3>Rate Yourself:</h3>
                    </div>
                  </div>
                  {form.questions?.map((question, index) => (
                    <div key={index} className="form-field">
                      <label>{question.label}</label>
                      {question.options?.length > 0 ? (
                        <select
                          className="form-select"
                          value={selfRating[question.label] || ''}
                          onChange={(e) => setSelfRating(prev => ({
                            ...prev,
                            [question.label]: e.target.value
                          }))}
                        >
                          <option value="">Select an option</option>
                          {question.options.map((option, i) => (
                            <option key={i} value={option}>{option}</option>
                          ))}
                        </select>
                      ) : (
                        <input
                          type="text"
                          className="form-input"
                          value={selfRating[question.label] || ''}
                          onChange={(e) => setSelfRating(prev => ({
                            ...prev,
                            [question.label]: e.target.value
                          }))}
                        />
                      )}
                    </div>
                  ))}
                  <button
                    className="submit-button"
                    onClick={() => handleSelfRatingSubmit(form._id)}
                  >
                    Submit
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      
  {activeSection === 'feedback' && (
    <div className="managerfeedbacksection" >
      <h2 className="manager-feedback-cards-heading">Anonymous Feedbacks</h2> 
      <div className="manager-feedback-cards">
        {anonymousFeedbacks?.feed && anonymousFeedbacks.feed.length > 0 ? (
          anonymousFeedbacks.feed.slice().reverse().map((feedback, index) => (
            <div key={feedback._id || index} className="m-feedback-card" onClick={() => handleFeedbackCardClick(feedback)}>
              <div className="manager-feedback-header">
                <i className="fa-solid fa-user-secret"></i>
                <span className="manager-feedback-date">
                  <strong> Recipient :</strong> {feedback.Employename}
                </span>
              </div>
              <div className="manager-feedback-content">
                <strong>Reason :-</strong>
                <p>{feedback.Reason}.</p>
              </div>
            </div>
          ))
        ) : (
          <div className="no-feedback-message">
            <p>No feedback available at this time.</p>
          </div>
        )}
      </div>
      {selectedFeedback && (
        <div className="feedback-detail-modal" style={{}}>
          <button 
            className="feedback-detail-modale-close" 
            onClick={() => setSelectedFeedback(null)}
          >
            Close
          </button>

          <h3>Feedback Details</h3>
          <p><strong>Recipient:</strong> {selectedFeedback.Employename}</p>
          <p><strong>Reason:</strong> {selectedFeedback.Reason}</p>
        </div>
      )}
    </div>
  )}


    </div>
    </>
  );
};

export default ManagerDashboard;