import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "./ApproveLeave.css";
import { server } from "../api/apiservice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ApproveLeave = () => {
  const { token } = useParams();
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [comments, setComments] = useState("");
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [pendingAction, setPendingAction] = useState(null);

  const managerId = localStorage.getItem("userId");
  // console.log("cccccccccccccc", managerId1);

  // console.log("responseresponse", leaveRequests);

  console.log("leaveRequests",leaveRequests)
  const tokenn = localStorage.getItem("token");
  // console.log("ghjh", tokenn);

  useEffect(() => {
    const fetchLeaveRequests = async () => {
      try {
        // console.log("Manager ID:", managerId);
        // console.log("Authorization Tokennnnnnnnn:", tokenn);

        if (!managerId) {
          console.error("Manager ID is missing");
          return;
        }

        const requestBody = {
          managerId: managerId,
        };

        console.log("requestBody", requestBody);

        const response = await axios.get(`${server}leave/leaverequests`, {
          headers: { Authorization: `${tokenn}` },
          params: requestBody,
        });
        console.log("Response Data:", response.data);

        setLeaveRequests(response.data);
      } catch (error) {
        console.error("Failed to fetch leave requests:", error);
      }
    };

    fetchLeaveRequests();
  }, [tokenn, managerId]);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleDateSelect = (request) => {
    if (
      request.status === "Filled" ||
      request.status === "Approved" ||
      request.status === "Rejected"
    ) {
      setMessage(
        "This leave request has already been processed and cannot be modified."
      );
    } else {
      setMessage("");
      setSelectedRequest(request);
      setComments("");
    }
  };

  const handleApproval = (status) => {
    setPendingAction(status);
    setShowConfirmation(true);
  };

  const confirmApproval = async () => {
    if (selectedRequest) {
      try {
        await axios.put(
          `${server}leave/approveLeave/${selectedRequest._id}`,
          {
            status: pendingAction ? "Approved" : "Rejected",
            comments,
          },
          { headers: { Authorization: `${tokenn}` } }
        );

        setLeaveRequests(
          leaveRequests.map((req) =>
            req._id === selectedRequest._id ? { ...req, status: pendingAction ? "Approved" : "Rejected" } : req
          )
        );

        toast.success(
          `Leave request has been ${pendingAction ? "approved" : "rejected"}!`
        );
        setSelectedRequest(null);
      } catch (error) {
        toast.error("Failed to update leave request. Please try again.");
      } finally {
        setShowConfirmation(false);
        setPendingAction(null);
      }
    }
  };

  return (
    <div className="approve-leave-container">
      <ToastContainer position="top-right" autoClose={3000} />
      <div className="leave-requests">
        <h2>Leave Requests</h2>
        {leaveRequests.length > 0 ? (

          <ul>
            {leaveRequests.sort((a, b) => new Date(b.appliedDate) - new Date(a.appliedDate)) .map((request) => (
              <li key={request._id}>
                <span>{request.employee.name}</span> {" "}
                <span>{request.leaveType}</span> {" "}
                <span>{request.leaveDuration}</span> {" "}
                <span>{formatDate(request.appliedDate)}</span>
                {/* <span> {request.status}</span> */}
                {request.status === "Approved" ? (
                  <button className="status-approved" onClick={() => setMessage("This leave request has already been approved.")}>Approved</button> 
                ) : request.status === "Rejected" ? (
                  <button className="status-rejected" onClick={() => setMessage("This leave request has already been rejected.")}>Rejected</button> 
                ) : request.status === "Request Approved Cancel" ? (
                  <button className="status-cancelled" onClick={() => setMessage("The approved leave has been canceled by the employee")}>Cancelled</button> 
                ) : (
                  <button className="status-pending" onClick={() => handleDateSelect(request)}>View</button> 
                )}
              </li>
            ))}
          </ul>
        ) : (
          <p>No leave requests found.</p>
        )}
      </div>

      {selectedRequest && (
        <div className="leave-details-card">
          <FontAwesomeIcon
            icon={faTimes}
            className="close-icon"
            onClick={() => setSelectedRequest(null)}
          />
          <h2>Leave Request Details</h2>
          <p>
            <strong>Employee:</strong> {selectedRequest.employee.name} {" "}            
         </p>
          <p>
          <strong>Email:</strong> {selectedRequest.employee.email}           
         </p>
          <p>
            <strong>Type:</strong> {selectedRequest.leaveType}
          </p>
          <p>
            <strong>Duration:</strong>{" "}
            {formatDate(selectedRequest.leaveStartDate)}<strong> To </strong>{" "}
            {formatDate(selectedRequest.leaveEndDate)}
          </p>
          <p>
            <strong>Reason:</strong> {selectedRequest.reason}
          </p>
          <p>
            <strong>Leave Duration:</strong> {selectedRequest.leaveDuration}
          </p>
          <div className="approval-section">
            <textarea
              placeholder="Enter comments (optional)"
              value={comments}
              onChange={(e) => setComments(e.target.value)}
            />
            <div className="approval-buttons">
              <button
                className="approve-button"
                onClick={() => handleApproval(true)}
              >
                Approve
              </button>
              <button
                className="reject-button"
                onClick={() => handleApproval(false)}
              >
                Reject
              </button>
            </div>
          </div>
        </div>
      )}

      {showConfirmation && (
        <div className="confirmation-dialog">
          <div className="confirmation-content">
            <h3>Confirm Action</h3>
            <p>
              Are you sure you want to {pendingAction ? "approve" : "reject"}{" "}
              this leave request? {pendingAction ? "" : "This action will not allow acceptance."}
            </p>
            <div className="confirmation-buttons">
              <button onClick={confirmApproval}>Yes, Confirm</button>
              <button onClick={() => setShowConfirmation(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}

      <p className="message">{message}</p>
    </div>
  );
};

export default ApproveLeave;
