import React, { useState, useEffect } from "react";
import "./Sidebar.css"; // Import Sidebar CSS
import "@fortawesome/fontawesome-free/css/all.min.css";
import { FaFileUpload } from "react-icons/fa";
import axios from "axios";
import ProfileUpdate from "./ProfileUpdate/ProfileUpdate";
import { server } from "../api/apiservice";
import EmployeeDashboard from "./Employee/EmployeeDashboard";
import MainDashboard from "./DashBoard/MainDashboard";
import AllUsersLeaveHistory from "../leaveData/AllUsersLeaveHistory";
import ProfileData from "./EmpProfileDetails/ProfileData";
import NavigationBar from "./NavBar/NavigationBar"



const SidebarAll = ({
  onFormSelect,
  onSignout,
  onShowUsers,
  userRole,
  onExistingForms,
  onUploadFile,
  onTopRated,
  onHolidays,
  onAttendance,
  onHistory,
  onDashboard,
  onEmp,
  onProfile,
  onUpdate,
  onFeedback,
  onApproval,
  onToggleSidebar,
  onHierarchyChart,
  HierarchyChart,
  
  
}) => {
  // const [isOpen, setIsOpen] = useState(false);
  const [indicatorStyle, setIndicatorStyle] = useState({});
  const [userData, setUserData] = useState(null);
  const [showDashboard, setShowDashboard] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isAllUsersLeaveHistoryOpen, setIsAllUsersLeaveHistoryOpen] = useState(false);
  const [showProfileOptions, setShowProfileOptions] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null); // State to hold the timeout ID


  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem("token");
      if (!token) return;

      try {
        const { data } = await axios.get(
          `${server}auth/dashboard`,
          {
            headers: { Authorization: `${token}` },
          }
        );
        const { name, role, dateOfBirth, joiningDate, profileImage } = data;
        console.log("Profile Image URL:", data.profileImage);
        setUserData(data); // Assuming data contains user info
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    const menuItems = document.querySelectorAll(".sidebar ul li");
    menuItems.forEach((item) => {
      item.addEventListener("mouseover", () => {
        const itemHeight = item.offsetHeight;
        const offsetTop = item.offsetTop;
        setIndicatorStyle({ top: `${offsetTop}px`, height: `${itemHeight}px` });
      });
    });
  }, []);



  const toggleSidebar = () => {
    const newState = !isOpen;
    setIsOpen(newState);
    onToggleSidebar?.(newState); // Notify parent component
  };

  // Ensure the sidebar can be toggled open/close
  const handleMouseEnter = () => {
    if (timeoutId) {
      clearTimeout(timeoutId); // Clear any existing timeout
    }
    setIsOpen(true); // Open sidebar on hover
    onToggleSidebar(true); // Notify parent component
  };

  const handleMouseLeave = () => {
    const id = setTimeout(() => {
      setIsOpen(false); // Close sidebar after delay
      onToggleSidebar(false); // Notify parent component
    }, 300); // Match this duration with your CSS transition duration
    setTimeoutId(id); // Store the timeout ID
  };

  const toggleAllUsersLeaveHistory = () => {
    setIsAllUsersLeaveHistoryOpen(!isAllUsersLeaveHistoryOpen);
  };

  const toggleProfileOptions = () => {
    setShowProfileOptions(!showProfileOptions);
  };

  console.log("user data", userData);

  return (
    <div className="sidebar-wrapper" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <NavigationBar/>
      <div className={`sidebar ${isOpen ? "open" : ""}`} id="sidebar">
        <ul>
          <div className="profile1">

            <img
              src={userData?.profileImage ? `${server}${userData.profileImage}` : "profilemen.avif"}
              alt="Profile Picture"

            />
          </div>
          <div className={`user-name ${isOpen ? "show" : "hide"}`}>
            <span>{userData?.name}</span>
          </div>


          <div
            className="indicator"
            id="indicator"
            style={indicatorStyle}
          ></div>

          {userRole === "Employee" && (
            <>
              <li onClick={onDashboard}>
                <i className="icon fa-solid fa-home"></i>
                <span>Dashboard</span>
              </li>
              <li onClick={onEmp}>
                <i className="icon fa-solid fa-chart-line"></i>
                <span>Appraisal</span>
              </li>
              {/* <li onClick={onHistory}>
                <i className="fas fa-calendar-alt"></i>
                <span>View History</span>
              </li> */}
              <li onClick={onFeedback}>
                <i className="fas fa-comment"></i>
                <span>Feedback</span>
              </li>

              <li onClick={toggleProfileOptions}>
                <i className='fas fa-user'></i>
                <span>{showProfileOptions ? 'User Details' : 'User Details'}</span>

              </li>
              {showProfileOptions && (
                <>
                  <li style={{ marginLeft: "30px" }} onClick={onProfile}>
                    <span>  Personal Details</span>
                  </li>

                  <li style={{ marginLeft: "30px" }} onClick={onUpdate}>
                    <span>Profile Update</span>
                  </li>
                  <li style={{ marginLeft: "30px" }} onClick={onHierarchyChart}>
                    <span>Hierarchy Chart</span>
                  </li>
          
                </>
              )}

              {/* <li onClick={toggleAllUsersLeaveHistory}>
                <i className="icon fa-solid fa-history"></i>
                <span>All Users Leave History</span>
              </li> */}

            </>
          )}

          {/* Sidebar for CEO */}
          {userRole === "CEO" && (
            <>
              <li onClick={onFormSelect}>
                <i className="icon fa-solid fa-rectangle-list"></i>
                <span>Select Form</span>
              </li>
              <li onClick={onShowUsers}>
                <i className="icon fa-solid fa-users"></i>
                <span>All Users</span>
              </li>
              <li onClick={onTopRated}>
                <i className="icon fa-solid fa-crown"></i>
                <span>Top Rated Emp</span>
              </li>
              <li onClick={onHolidays}>
                <i className="fas fa-calendar-alt"></i>
                <span>Holidays</span>
              </li>
              <li onClick={onApproval}>
              <i className='fas fa-clock'></i>

                <span>Leave Requests</span>
              </li>


            </>
          )}

          {/* Sidebar for Admin */}
          {userRole === "Admin" && (
            <>
              <li onClick={onShowUsers}>
                <i className="icon fa-solid fa-users"></i>
                <span>Create User</span>
              </li>
              <li onClick={onFormSelect}>
                <i className="icon fa-solid fa-rectangle-list"></i>
                <span>Create Form</span>
              </li>
              <li onClick={onExistingForms}>
                <i className="icon fa-solid fa-chart-bar"></i>
                <span>Existing Form</span>
              </li>
              {/* <li onClick={onUploadFile}>
                <i className="icon fa-solid fa-upload"></i>
                <span>Upload Form</span>
              </li> */}
            </>
          )}

          {/* Sidebar for Manager */}
          {userRole === "Manager" && (
            <>
              <li onClick={onDashboard}>
                <i className="icon fa-solid fa-home"></i>
                <span>Dashboard</span>
              </li>

              <li onClick={onFormSelect}>
                <i className="icon fa-solid fa-rectangle-list"></i>
                <span>Select Form</span>
              </li>

              <li onClick={onShowUsers}>
                <i className="icon fa-solid fa-users"></i>
                <span>All Users</span>
              </li>
              <li onClick={onTopRated}>
                <i className="icon fa-solid fa-crown"></i>
                <span>Top Rated Emp</span>
              </li>
              {/* <li onClick={onHolidays}>
                <i className="fas fa-calendar-alt"></i>
                <span>Holidays</span>
              </li> */}
              <li onClick={toggleAllUsersLeaveHistory}>
                <i className="icon fa-solid fa-history"></i>
                <span>Emp Leave History</span>
              </li>
              <li onClick={onFeedback}>
                <i className="icon fa-solid fa-comment-dots"></i>
                <span>Feedbacks</span>
              </li>
              <li onClick={onApproval}>
              <i className='fas fa-clock'></i>



                <span>Leave Requests </span>
              </li>
          

            </>
          )}
          {/* Sidebar for Hr */}
          {userRole === "HR" && (
            <>
              <li onClick={onDashboard}>
                <i className="icon fa-solid fa-home"></i>
                <span>Dashboard</span>
              </li>
              <li onClick={onFormSelect}>
                <i className="icon fa-solid fa-rectangle-list"></i>
                <span>Select Form</span>
              </li>

              <li onClick={onShowUsers}>
                <i className="icon fa-solid fa-users"></i>
                <span>All Users</span>
              </li>
              <li onClick={onTopRated}>
                <i className="icon fa-solid fa-crown"></i>
                <span>Top Rated Emp</span>
              </li>

              <li onClick={toggleAllUsersLeaveHistory}>
                <i className="icon fa-solid fa-history"></i>
                <span>Emp Leave History</span>
              </li>
              <li onClick={onApproval}>
              <i className='fas fa-clock'></i>


                <span>Leave Requests</span>
              </li>
            </>
          )}
          {/* Common to all users */}
          <li onClick={onSignout}>
            <i className="icon fa-solid fa-right-from-bracket"></i>
            <span>Logout</span>
          </li>
         
        </ul>
      </div>
      <button className="toggle-btn" id="toggleBtn" onClick={toggleSidebar}>
        <i
          className={`fa-solid ${isOpen ? "fa-chevron-left" : "fa-chevron-right"
            }`}
        ></i>
      </button>
      <AllUsersLeaveHistory isOpen={isAllUsersLeaveHistoryOpen} toggleAllUsersLeaveHistory={toggleAllUsersLeaveHistory} />
    </div>
  );
};

export default SidebarAll;
