import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ApplyLeave.css';
import { server } from '../api/apiservice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ApplyLeave = ({ isOpen, toggleApplyLeave }) => {
  const [leaveType, setLeaveType] = useState('');
  const [leaveStartDate, setLeaveStartDate] = useState('');
  const [leaveEndDate, setLeaveEndDate] = useState('');
  const [leaveDuration, setLeaveDuration] = useState('Full Day');
  const [reason, setReason] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedApprover, setSelectedApprover] = useState('');
  const [Approvers, setApprovers] = useState('')
  const uName = localStorage.getItem("userName");

  console.log("Approversssssss", Approvers);
  console.log("selectedApprover", selectedApprover)

  useEffect(() => {
    const today = new Date().toISOString().split('T')[0];
    setLeaveStartDate(today);
    console.log("selectedApprover", selectedApprover)
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowConfirmation(true);
  };

  const confirmSubmit = async () => {
    setShowConfirmation(false);
    setLoading(true);

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${server}leave/applyLeave`, {
        uName,
        leaveType,
        leaveStartDate,
        leaveEndDate: leaveEndDate || leaveStartDate,
        leaveDuration,
        reason,
        manager_id: selectedApprover,
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${token}`,
        },
      });

      toast.success('Leave application submitted successfully!');
      setMessage(response.data.message);
      // Clear form after submission
      setLeaveType('');
      setLeaveStartDate('');
      setLeaveEndDate('');
      setLeaveDuration('Full Day');
      setReason('');

      // Close the form after a short delay
      setTimeout(() => {
        toggleApplyLeave();
      }, 2000);
    } catch (error) {
      const errorMsg = error.response?.data?.message || 'Failed to apply for leave. Please try again.';
      toast.error(errorMsg);
      setMessage(errorMsg);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!document.querySelector('.apply-leave-container').contains(event.target)) {
        toggleApplyLeave();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const renderLeaveDurationOptions = () => {
    if (leaveStartDate === leaveEndDate) {
      return (
        <>
          <option value="Full Day">Full Day</option>
          <option value="Half Day">Half Day</option>
        </>
      );
    }
    return <option value="Full Day">Full Day</option>;
  };


  const fetchUsers = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${server}userRoutes/users`, {
        headers: { Authorization: ` ${token}` },
      });
      console.log("Fetched users data:", response.data);

      // Filter users to include only Managers, HR, and CEO, excluding the logged-in user
      const filteredUsers = response.data.filter(user =>
        (user.role === 'Manager' || user.role === 'HR' || user.role === 'CEO') && user.name !== uName
      );
      console.log("Filtered Users:", filteredUsers);
      setApprovers(filteredUsers);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <>
      <ToastContainer position="top-right" autoClose={3000} />
      <div className="Apply-button-container">
        <button className="Apply-button-click" onClick={toggleApplyLeave} style={{ border: "none" }}>
          {/* <i className="fa-regular fa-pen-to-square"></i> */}
        </button>
      </div>
      {isOpen && (
        <div className='apply-overlay'>
          <div className="apply-leave-container">
            <FontAwesomeIcon
              icon={faTimes}
              className="close-icon"
              onClick={toggleApplyLeave}
            />
            <h2>Apply for Leave</h2>
            <div className="form-and-image-container">
              <form className="apply-leave-form" onSubmit={handleSubmit}>
                <label htmlFor="leaveType">Leave Type:</label>
                <select
                  id="leaveType"
                  value={leaveType}
                  onChange={(e) => setLeaveType(e.target.value)}
                  required
                >
                  <option value="">Select Leave Type</option>
                  <option value="Sick Leave">Sick Leave</option>
                  <option value="Casual Leave">Casual Leave</option>
                  <option value="Annual Leave">Annual Leave</option>
                </select>

                <div className="date-fields">
                  <div>
                    <label htmlFor="leaveStartDate">Start Date:</label>
                    <input
                      id="leaveStartDate"
                      type="date"
                      value={leaveStartDate}
                      onChange={(e) => setLeaveStartDate(e.target.value)}
                      min={new Date().toISOString().split('T')[0]}
                      required
                    />
                  </div>
                  {leaveStartDate && (
                    <div>
                      <label htmlFor="leaveEndDate">End Date:</label>
                      <input
                        id="leaveEndDate"
                        type="date"
                        value={leaveEndDate}
                        onChange={(e) => setLeaveEndDate(e.target.value)}
                        min={leaveStartDate}
                        required
                      />
                    </div>
                  )}
                </div>

                <label htmlFor="leaveDuration">Leave Duration:</label>
                <select
                  id="leaveDuration"
                  value={leaveDuration}
                  onChange={(e) => setLeaveDuration(e.target.value)}
                >
                  {renderLeaveDurationOptions()}
                </select>

                <label htmlFor="reason">Reason for Leave:</label>
                <textarea
                  id="reason"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  placeholder="Enter your reason"
                  required
                ></textarea>

                <label htmlFor="approver">Select Approver:</label>
                <select
      id="approver"
      value={selectedApprover}
      onChange={(e) => setSelectedApprover(e.target.value)}
      required
    >
      <option value="">Select Approver</option>
      {Approvers.length > 0 ? (
          Approvers.map((approver) => (
              <option key={approver._id} value={approver._id}>
                  {approver.name} 
              </option>
          ))
      ) : (
          <option value="">No Approvers Available</option>
      )}
    </select>

                <button type="submit" disabled={loading}>
                  {loading ? 'Submitting...' : 'Submit'}
                </button>
              </form>
              <img src="vacation.jpg" alt="Description" className="leave-image" />
            </div>

            {message && (
              <p className={message.includes('successfully') ? 'message-success' : 'message-error'}>
                {message}
              </p>
            )}

            {showConfirmation && (
              <div className="confirmation-dialog">
                <div className="confirmation-content">
                  <h3>Confirm Leave Application</h3>
                  <p>Are you sure you want to submit this leave application?</p>
                  <div className="confirmation-buttons">
                    <button onClick={confirmSubmit}>Yes, Submit</button>
                    <button onClick={() => setShowConfirmation(false)}>Cancel</button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ApplyLeave;
