import React, { useState, useEffect } from "react";
import axios from "axios";
import "./NavigationBar.css"; // Add CSS for styling
import { server } from "../../api/apiservice";
import Notifications from "../Notifications";
const NavigationBar = () => {
  const [searchInput, setSearchInput] = useState("");
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [showProfileDropdown, setShowProfileDropdown] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null); // State to store clicked user details
  // const [user, setUser] = useState(null);
  const suggestions = [
    "Project Report",
    "Team Members",
    "Dashboard",
    "Meeting Notes",
  ]; // Dummy data
  const [userData, setUserData] = useState(null);
  const handleSearchChange = (e) => {
    const input = e.target.value;
    setSearchInput(input);
    if (input) {
      const filteredUsers = users.filter((employee) =>
        employee.name.toLowerCase().includes(input.toLowerCase())
      );
      setSearchSuggestions(filteredUsers);
    } else {
      setSearchSuggestions([]);
    }
  };
  const toggleProfileDropdown = () => {
    setShowProfileDropdown(!showProfileDropdown);
  };
  // Fetch users on component mount
  useEffect(() => {
    fetchUsers();
  }, []);
  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem("token");
      if (!token) return;
      try {
        const { data } = await axios.get(`${server}auth/dashboard`, {
          headers: { Authorization: `${token}` },
        });
        const {
          name,
          role,
          dateOfBirth,
          joiningDate,
          profileImage,
          yourmanager,
        } = data;
        console.log("Profile Image URL:", data.joiningDate);
        setUserData(data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    fetchUserData();
  }, []);
  const fetchUsers = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${server}userRoutes/users`, {
        headers: { Authorization: ` ${token}` },
      });
      console.log("Fetched users data:", response.data); // Debug log
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };
  // Handle user click from suggestions
  const handleUserClick = (user) => {
    console.log("Selected user data:", user); // Debug log
    setSearchInput(user.name);
    setSelectedUser(user);
    setSearchSuggestions([]);
    setSearchInput("");
  };
  // Add this new function near your other handlers
  const handleOverlayClick = (e) => {
    // Close only if clicking the overlay (not the card itself)
    if (e.target.className === "user-profile-overlay") {
      setSelectedUser(null);
    }
  };
  // Construct profile image URL with server URL + relative path
  return (
    <>
      <div className="nav-container">
        <nav className="navbar">
          <img src="123.jpg" alt="Logo" className="nav-logo" />
          <div className="nav-search-box">
            <input
              type="text"
              placeholder="Search Employees"
              value={searchInput}
              onChange={handleSearchChange}
              className="search-input"
              maxLength={50}
            />
            {searchSuggestions.length > 0 ? (
              <div className="dropdown-suggestions">
                <ul>
                  {searchSuggestions.map((employee, index) => (
                    <li key={index} onClick={() => handleUserClick(employee)}>
                      {employee.name}
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              searchInput && <div className="dropdown-suggestions">No user found</div> 
            )}
          </div>

          <div className="nav-items">
            <button className="notification-btn">
              <Notifications />{" "}
            </button>
            <div className="profile-section" onClick={toggleProfileDropdown}>
              <img
                src={
                  userData?.profileImage
                    ? `${server}${userData.profileImage}`
                    : "profilemen.avif"
                }
                alt="profilemen.avif"
                className="profile-img"
              />
            </div>
          </div>
        </nav>
      </div>
      {/* Moved and styled user details outside navbar */}
      {selectedUser && (
        <div className="user-profile-overlay" onClick={handleOverlayClick}>
          <div className="user-profile-card">
            <button
              className="close-profile"
              onClick={() => setSelectedUser(null)}
            >
              Close
            </button>
            <div className="user-profile-header">
              <img
                src={
                  selectedUser.profileImage
                    ? `${server}${selectedUser.profileImage}`
                    : "profilemen.avif"
                }
                alt="User Profile"
                className="user-profile-image"
                onError={(e) => {
                  e.target.src = "profilemen.avif";
                }}
              />
              <h2 className="user-profile-name">{selectedUser.name}</h2>
            </div>
            <div className="user-profile-details">
              <p>
                <strong>Email:</strong> {selectedUser.email}
              </p>
              <p>
                <strong>Role:</strong> {selectedUser.roleEmp || "Employee"}
              </p>
              <p>
                <strong>Manager:</strong>{" "}
                {selectedUser.yourmanager || "Not Assigned"}
              </p>
              {/* <p><strong>joiningDate:</strong> {selectedUser.joiningDate}</p> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default NavigationBar;
