import React, { useState, useEffect } from 'react';
import axios from 'axios';

import './AllUsersLeaveHistory.css'
import { server } from '../api/apiservice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';


const AllUsersLeaveHistory = ({ isOpen, toggleAllUsersLeaveHistory }) => {
    const [leaveHistory, setLeaveHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [statusFilter, setStatusFilter] = useState('');
    const [filteredHistory, setFilteredHistory] = useState([]);


    useEffect(() => {
        const fetchLeaveHistory = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${server}leave/allLeaves`, {
                    headers: { Authorization: `${token}` },
                });
                console.log('User Leave History Response:', response.data);
                setLeaveHistory(response.data);
                setFilteredHistory(response.data); 
            } catch (error) {
                console.error('Error fetching leave history:', error);
                setMessage('Failed to fetch leave history. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchLeaveHistory();
    }, []);

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            const container = document.querySelector('.apply-leave-container');
            if (container && !container.contains(event.target)) {
                toggleAllUsersLeaveHistory();
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    const handleSearch = () => {
        const filtered = leaveHistory.filter(leave => {
            const matchesName = leave.employee?.name.toLowerCase().includes(searchTerm.toLowerCase());
            const matchesStatus = leave.status.toLowerCase().includes(statusFilter.toLowerCase());
            return matchesName && matchesStatus;
        });
        setFilteredHistory(filtered);
        setSearchTerm('')
    };

    const handleGoBack = () => {
        setSearchTerm('');
        setStatusFilter('');
        setFilteredHistory([]);
    };




    return (
        <>
            {/* <div className="Apply-button-container">
                <button className="Apply-button-click" onClick={toggleAllUsersLeaveHistory} style={{ border: "none" }}>
                  
                </button>
            </div> */}

            {isOpen && (
                <div className='All-history-overlay'>
                    <div className="All-leave-history-container apply-leave-container">
                        <div className="search-filter-container">
                            <input 
                                type="text" 
                                placeholder="Search by name" 
                                value={searchTerm} 
                                onChange={(e) => setSearchTerm(e.target.value)} 
                                className="All-history-search-input"
                            />
                            <select 
                                value={statusFilter} 
                                onChange={(e) => setStatusFilter(e.target.value)} 
                                className="All-history-status-filter"
                            >
                                <option value="">Filter by status</option>
                                <option value="Approved">Approved</option>
                                <option value="Pending">Pending</option>
                                <option value="Rejected">Rejected</option>
                            </select>
                            <button onClick={handleSearch} className="search-button">Search</button>
                        </div>
                        <FontAwesomeIcon
                            icon={faTimes}
                            className="close-icon"
                            onClick={toggleAllUsersLeaveHistory}
                        />
                        <h2 className='LeaveHistoryH2'>All Employees Leave History</h2>
                        <button onClick={() => setFilteredHistory(leaveHistory)} className="show-all-button">Show All</button>

                        {loading ? (
                            <p className="loading-message">Loading...</p>
                        ) : (filteredHistory.length ? (
                            <div className="table-wrapper">
                                <table className="leave-history-table">
                                    <thead>
                                        <tr>
                                            <th>Employee</th>
                                            <th>Leave Type</th>
                                            <th>Duration</th>
                                            <th>Status</th>
                                            <th>Applied Date</th>
                                            <th>Comments</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredHistory.map((leave) => (
                                            <tr key={leave._id}>
                                                <td>{leave.employee?.name || 'N/A'}</td>
                                                <td>{leave.leaveType}</td>
                                                <td>{`${formatDate(leave.leaveStartDate)} - ${formatDate(leave.leaveEndDate)}`}</td>
                                                <td>{leave.status}</td>
                                                <td>{formatDate(leave.appliedDate)}</td>
                                                <td dangerouslySetInnerHTML={{ __html: leave.comments || 'No comments' }}></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <p>No {statusFilter} leave history available.</p>
                        ))}
                        {message && <p className="message">{message}</p>}
                    </div>
                </div>
            )}
        </>
    );
};

export default AllUsersLeaveHistory;
