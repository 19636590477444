import React from 'react';
import Tree from 'react-d3-tree';
import './hierarchychart.css';
import downloadImage from '../../images/download.jpg';

const HierarchyChart = () => {
    // Static data for the hierarchy with images
    const data = {
        name: 'Abhijeet Ranadhir',
        role: 'CEO',
        image: downloadImage, // Placeholder image
        children: [
            {
                name: 'Niharika',
                role: 'Manager',
                image: downloadImage, // Placeholder image
                children: [
                    { name: 'Nagarjuna', role: 'Senior Network Engineer', image: downloadImage },
                    { name: 'Govardhan', role: 'Account Representative', image: downloadImage },
                    { name: 'Deeptendu', role: 'Account Representative', image: downloadImage },
                    { name: 'Rajanikanth', role: 'Account Representative', image: downloadImage },
                    { name: 'Hanumesh', role: 'Account Representative', image: downloadImage },
                    { name: 'Basavaraj', role: 'Account Representative', image: downloadImage },
                    { name: 'Karan', role: 'Account Representative', image: downloadImage },
                    { name: 'Bharat', role: 'Account Representative', image: downloadImage },
                    { name: 'Alvita', role: 'Account Representative', image: downloadImage },
                    { name: 'Amarjeet', role: 'Account Representative', image: downloadImage },
                    { name: 'Ananya', role: 'Account Representative', image: downloadImage },
                    { name: 'Chandrashekhar', role: 'Account Representative', image: downloadImage },
                    { name: 'Chinmay', role: 'Account Representative', image: downloadImage },
                    { name: 'Kusuma', role: 'Account Representative', image: downloadImage },
                    { name: 'Rucha', role: 'Account Representative', image: downloadImage },
                    { name: 'Vishwanath', role: 'Account Representative', image: downloadImage },
                    
                ],
            },
            {
                name: 'Kiran',
                role: 'HR',
                image: downloadImage, // Placeholder image
                // children: [
                //     { name: 'Savannah Nguyen', role: 'System Architect', image: downloadImage },
                //     { name: 'Arlene McCoy', role: 'Senior Network Architect', image: downloadImage },
                // ],
            },
            
        ],
    };

    const containerStyles = {
        width: '100%',
        height: '500px',
    };

    const renderNode = ({ node }) => (
        <g>
            <image href={node.image} x="0" y="-20" width="50" height="50" />
            <text fill="black" strokeWidth="1" x="0" y="20" textAnchor="middle">
                {node.name} ({node.role})
            </text>
        </g>
    );

    return (
        <div  className="HierarchyChart-c" style={containerStyles}>
            <Tree 
                data={data} 
                renderCustomNode={renderNode} 
                orientation="vertical" 
                pathFunc="straight" // Use straight lines
                translate={{ x: 600, y: 100 }}
                 nodeSize={{ x: 100, y: 150 }}

            />
        </div>
    );
};

export default HierarchyChart;
