import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './FormBuilder.css';
import SidebarAll from '../sidebar';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { server } from '../../api/apiservice';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useDropzone } from 'react-dropzone'; // For drag-and-drop file upload
import NavigationBar from '../NavBar/NavigationBar';

const FormBuilder = () => {
  const [formTitle, setFormTitle] = useState('');
  const [questions, setQuestions] = useState([]);
  const [existingForms, setExistingForms] = useState([]);
  const [form, setForm] = useState({ name: '', email: '', password: '', role: '', dateOfBirth: '', joiningDate: '' });
  const [expandedForm, setExpandedForm] = useState(null);
  const [formToEdit, setFormToEdit] = useState(null);
  const [activeSection, setActiveSection] = useState('createUser'); // State to handle sidebar sections
  const [userRole, setUserRole] = useState('Admin'); // Assume a role for demonstration//adding
  const [errors, setErrors] = useState({ name: '', email: '', password: '' });
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const [file, setFile] = useState(null); // State to store the uploaded file
  const [editingFormId, setEditingFormId] = useState(null); // State to track which form is being edited
  const [managers, setManagers] = useState([]);  // Store the list of managers
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State to manage sidebar visibility




  const navigate = useNavigate();

  // Check authentication on component mount
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/'); // Redirect to login if not authenticated
    }
  }, [navigate]);



  // Function to handle file upload
  const onDrop = (acceptedFiles) => {
    setFile(acceptedFiles[0]); // Store the file in state
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  // Function to handle form submission (for Excel upload)
  const handleUpload = async (e) => {
    e.preventDefault();

    if (!file) {
      toast.error('Please select an Excel file to upload');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post(`${server}admin/upload-attendance`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.success) {
        toast.success('File uploaded and data processed successfully!');
        console.log('Extracted Data:', response.data.data);
      } else {
        toast.error('Error processing file');
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      toast.error('Error uploading file');
    }
  };




  useEffect(() => {
    const fetchForms = async () => {
      try {
        const response = await axios.get(`${server}admin/forms`);
        setExistingForms(response.data);
      } catch (error) {
        console.error('Error fetching forms:', error);
      }
    };

    fetchForms();
  }, []);

  const toggleFormContent = (formId) => {
    setExpandedForm(expandedForm === formId ? null : formId);
  };

  const addQuestion = () => {
    setQuestions([...questions, { label: '', options: [] }]);
  };

  const handleQuestionChange = (index, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].label = value;
    setQuestions(updatedQuestions);
  };

  // const addOption = (questionIndex) => {
  //   const updatedQuestions = [...questions];
  //   updatedQuestions[questionIndex].options.push('');
  //   setQuestions(updatedQuestions);
  // };

  const addOption = (questionIndex) => {
    const updatedQuestions = [...questions];

    // Check if the current number of options for the question is less than 10
    if (updatedQuestions[questionIndex].options.length < 10) {
      updatedQuestions[questionIndex].options.push('');
      setQuestions(updatedQuestions);
      toast.success('Option added successfully!', {
        position: "top-right",
        autoClose: 2000,
      });
    } else {
      toast.error('You can only add up to 10 options for each question.');
    }
  };

  const handleOptionChange = (questionIndex, optionIndex, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].options[optionIndex] = value;
    setQuestions(updatedQuestions);
  };

  const removeOption = (questionIndex, optionIndex) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].options.splice(optionIndex, 1);
    setQuestions(updatedQuestions);
    toast.success('Option removed successfully!', {
      position: "top-right",
      autoClose: 2000,
    });
  };

  const removeQuestion = (index) => {
    const updatedQuestions = questions.filter((_, i) => i !== index);
    setQuestions(updatedQuestions);
  };



  const handleSubmit = async () => {
    const formData = {
      title: formTitle,
      questions: questions.map((question) => ({
        label: question.label,
        options: question.options.map((option) => {
          return `${option.number} - ${option.text}`; // Combine number and text into one string
        })
      }))
    };
    console.log("Save Formmmmmmmmmm", formData);


    console.log(formData, "Form data being sent to the server");

    try {
      if (formToEdit) {
        await axios.put(`${server}admin/edit-form/${formToEdit._id}`, formData);
        toast.success('Form updated successfully!', {
          position: "top-right",
          autoClose: 2000,
        });
        setFormToEdit(null);
        setEditingFormId(null);// Reset editing state
      } else {
        await axios.post(`${server}admin/create-form`, formData);
        toast.success('Form created successfully!', {
          position: "top-right",
          autoClose: 2000,
        });
      }

      setFormTitle('');
      setQuestions([]);

      const response = await axios.get(`${server}admin/forms`);
      setExistingForms(response.data);

    } catch (error) {
      console.error('Error creating form:', error.response?.data || error.message);
      toast.error('Failed to submit the form. Please try again.', {
        position: "top-right",
        autoClose: 2000,
      });
    }
  };


  const handleChange = (e) => setForm({ ...form, [e.target.name]: e.target.value });

  // adding validation part
  const validate = () => {
    let valid = true;
    let errors = {};

    // Validate name
    if (!form.name) {
      errors.name = 'Name is required';
      valid = false;
    }

    // Validate email
    if (!form.email.includes('@sumerudigital.com')) {
      errors.email = 'Email must be in the format name@sumerudigital.com';
      valid = false;
    }

    // Validate password
    if (form.password.length < 6) {
      errors.password = 'Password must be at least 6 characters long';
      valid = false;
    }

    setErrors(errors);
    return valid;
  };


  const handleSubmitUser = async (e) => {
    e.preventDefault();
    // applying validation condition
    if (validate()) {

      try {

        const { data } = await axios.post(`${server}auth/register`, form);
        localStorage.setItem('token', data.token);
        toast.success('successful!', {
          position: "top-right",
          autoClose: 2000,
        });

        // Reset form state after successful registration
        setForm({
          name: '',
          email: '',
          password: '',
          role: '',
          joiningDate: '',
          dateOfBirth: ''
        });

      } catch (error) {
        console.error(error);
        toast.error('failed', {
          position: "top-right",
          autoClose: 2000,
        });

      }
    }
  };

  const deleteForm = async (formId) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this form?');
    if (confirmDelete) {
      try {
        await axios.delete(`${server}admin/delete-form/${formId}`);
        alert('Form deleted successfully!');
        const response = await axios.get(`${server}admin/forms`);
        setExistingForms(response.data);
        toast.success('successful!', {
          position: "top-right",
          autoClose: 2000,
        });

      } catch (error) {
        console.error('Error deleting form');
        toast.error('failed', {
          position: "top-right",
          autoClose: 2000,
        });


      }
    }
  };

  const editForm = (form) => {
    setEditingFormId(form._id);
    setFormTitle(form.title);

    const formattedQuestions = form.questions.map((question) => ({
      ...question,
      options: question.options.map((option) => {
        const [number, text] = option.split(' - ');
        return { number: number.trim(), text: text.trim() };
      })
    }));

    setQuestions(formattedQuestions);
    setFormToEdit(form);
    setActiveSection('existingForms') // Set the form being edited
  };


  const handleSignout = () => {
    localStorage.removeItem('token');

    navigate('/');
  };

  useEffect(() => {
    // Fetch managers when the component mounts
    const fetchManagers = async () => {
      try {
        const { data } = await axios.get(`${server}userRoutes/managers`);
        setManagers(data);  // Set the fetched managers
      } catch (error) {
        console.error("Error fetching managers:", error);
      }
    };

    fetchManagers();
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(prevState => !prevState); // Toggle sidebar state
  };

  return (
    <>  
    <NavigationBar/>
    <div className={`admin-dashboard-section ${isSidebarOpen ? "sidebar-expanded" : ""}`}>
      {/* Sidebar */}
      <SidebarAll
        onToggleSidebar={toggleSidebar} // Pass the toggle function to the sidebar
        onFormSelect={() => setActiveSection('createForm')}
        onSignout={() => handleSignout('Signout')} // Placeholder for signout function
        onShowUsers={() => setActiveSection('createUser')}
        onExistingForms={() => setActiveSection('existingForms')}
        onUploadFile={() => setActiveSection('uploadFile')}
        userRole={userRole}
      />

      {/* Main Content */}
      <div className="main">
        {/* Header */}
        <div className="header">
          <h1>ADMIN DASHBOARD</h1>
        </div>

        <div className="main-content">
          {activeSection === 'createUser' && (
            <div className="create-user-container">
              <div className="create-user-form">
                <h2>Create User</h2>
              </div>
              <form onSubmit={handleSubmitUser}>
                <div className="input-groups">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={form.name}
                    onChange={handleChange}
                    required
                  />
                  {errors.name && <p className="error">{errors.name}</p>}
                </div>
                <div className="input-groups">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={form.email}
                    onChange={handleChange}
                    autoComplete="off"
                    placeholder="name@sumerudigital.com"
                    required
                  />
                  {errors.email && <p className="error">{errors.email}</p>}
                </div>
                <div className="input-group password-group">
                  <label htmlFor="password">Password</label>
                  <div className='password-container'>
                    <input
                      type={showPassword ? 'text' : 'password'} // Toggle between text and password
                      id="password"
                      name="password"
                      value={form.password}
                      onChange={handleChange}
                      autoComplete="new-password"
                      placeholder="At least 6 characters"
                      required
                    />
                    <span className="password-toggle" onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  </div>
                  {errors.password && <p className="error">{errors.password}</p>}
                </div>
                <div className="input-groups">
                  <label htmlFor="role">Role</label>
                  <select
                    id="role"
                    name="role"
                    value={form.role}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select Role</option>
                    <option value="Employee">Employee</option>
                    <option value="Manager">Manager</option>
                    <option value="CEO">CEO</option>
                    <option value="HR">HR</option>
                  </select>
                </div>
                <div className="input-groups">Date Of Joining
                  <input type="date"
                    name="joiningDate"
                    value={form.joiningDate}
                    onChange={handleChange}
                    placeholder="Date of Joining"
                    required
                  />
                </div>
                <div className="input-groups">  Date Of Birth<br></br>
                  <input type="date"
                    name="dateOfBirth"
                    value={form.dateOfBirth}
                    onChange={handleChange}
                    placeholder="Date of Birth"
                    required
                  />
                </div>

                {/* Manager selection dropdown */}
                {form.role === 'Employee' && (
                  <div className="input-group">
                    <label htmlFor="manager_id">Select Manager</label>
                    <select
                      id="manager_id"
                      name="manager_id"
                      value={form.manager_id}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Select Manager</option>
                      {managers.map((manager) => (
                        <option key={manager._id} value={manager._id}>
                          {manager.name}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                <button type="submit" className="signup-buttons">Signup</button>
              </form>
            </div>
          )}

          {activeSection === 'createForm' && !editingFormId && (
            <div className="form-builder-container">
              <h2>{formToEdit ? 'Edit Form' : 'Create New Form'}</h2>

              <div className="form-builder-inputs">
                <input
                  type="text"
                  placeholder="Form Title"
                  value={formTitle}
                  onChange={(e) => setFormTitle(e.target.value)}
                  className="form-builder-input"
                />
                <button onClick={addQuestion} className="form-builder-button add-question-button">
                  Add Question
                </button>
              </div>

              {questions.map((question, questionIndex) => (
                <div key={questionIndex} className="form-builder-question">
                  <input
                    type="text"
                    placeholder="Question Label"
                    value={question.label}
                    onChange={(e) => handleQuestionChange(questionIndex, e.target.value)}
                    className="form-builder-input"
                  />
                  <div className="form-builder-instruction">
                    <p>Ex: 10 - The person works great in a team</p>
                  </div>

                  <div className="form-builder-options">
                    {question.options.map((option, optionIndex) => (
                      <div key={optionIndex} className="form-builder-option">
                        {/* Field for Option Number */}

                        <div>
                          <input
                            type="number"
                            placeholder={`Rating for option ${optionIndex + 1}`}
                            value={option.number !== undefined ? option.number : ""}
                            min="1"
                            max="5"
                            onChange={(e) => {
                              const value = parseInt(e.target.value);
                              if (!isNaN(value) && value >= 1 && value <= 5) {
                                handleOptionChange(
                                  questionIndex,
                                  optionIndex,
                                  { ...option, number: value }
                                );
                              }
                            }}
                            onKeyDown={(e) => {
                              // Prevent typing numbers outside 1-5 range
                              const invalidChars = ['e', 'E', '+', '-', '.'];
                              if (invalidChars.includes(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            className="form-builder-input"
                          />
                        </div>

                        {/* Field for Option Text */}
                        <input
                          type="text"
                          placeholder={`Option Text ${optionIndex + 1}`}
                          value={option.text !== undefined ? option.text : ""} // Fallback to empty string
                          onChange={(e) =>
                            handleOptionChange(
                              questionIndex,
                              optionIndex,
                              { ...option, text: e.target.value } // Update text field
                            )
                          }
                          className="form-builder-input"
                        />

                        <button
                          onClick={() => removeOption(questionIndex, optionIndex)}
                          className="form-builder-button remove-option-button"
                          style={{ margin: "0 0 0 10px", background: "red" }}
                        >
                          Remove Option
                        </button>
                      </div>
                    ))}
                    <button
                      onClick={() => addOption(questionIndex)}
                      className="form-builder-button add-option-button"
                    >
                      Add Option
                    </button>
                  </div>



                  <button
                    onClick={() => removeQuestion(questionIndex)}
                    className="form-builder-button remove-question-button"
                  >
                    Remove Question
                  </button>
                </div>
              ))}

              <button onClick={handleSubmit} className="form-builder-button save-form-button">
                {formToEdit ? 'Update Form' : 'Save Form'}
              </button>
            </div>
          )}

          {activeSection === 'existingForms' && (
            <div className="existing-forms-container">
              <h2>Existing Forms</h2>
              {existingForms.length > 0 ? (
                existingForms.map((form) => (
                  <div key={form._id} className="existing-form">
                    <h2 onClick={() => toggleFormContent(form._id)}>{form.title}</h2>
                    {expandedForm === form._id && (
                      <div className="form-questions">


                        {/* If this form is being edited, show the editable form fields */}
                        {editingFormId === form._id ? (
                          <div className="form-builder-container">
                            <input
                              type="text"
                              placeholder="Form Title"
                              value={formTitle}
                              onChange={(e) => setFormTitle(e.target.value)}
                              className="form-builder-input"
                            />

                            {questions.map((question, questionIndex) => (
                              <div key={questionIndex} className="form-builder-question">
                                <input
                                  type="text"
                                  placeholder="Question Label"
                                  value={question.label}
                                  onChange={(e) => handleQuestionChange(questionIndex, e.target.value)}
                                  className="form-builder-input"
                                />

                                <div className="form-builder-options">
                                  {question.options.map((option, optionIndex) => (
                                    <div key={optionIndex} className="form-builder-option">
                                      <input
                                        type="number"
                                        placeholder={`Rating for option ${optionIndex + 1}`}
                                        value={option.number !== undefined ? option.number : ''}
                                        onChange={(e) =>
                                          handleOptionChange(
                                            questionIndex,
                                            optionIndex,
                                            { ...option, number: e.target.value }
                                          )
                                        }
                                        className="form-builder-input"
                                      />
                                      <input
                                        type="text"
                                        placeholder={`Option Text ${optionIndex + 1}`}
                                        value={option.text !== undefined ? option.text : ''}
                                        onChange={(e) =>
                                          handleOptionChange(
                                            questionIndex,
                                            optionIndex,
                                            { ...option, text: e.target.value }
                                          )
                                        }
                                        className="form-builder-input"
                                      />

                                      <button
                                        onClick={() => removeOption(questionIndex, optionIndex)}
                                        className="form-builder-button remove-option-button"
                                      >
                                        Remove Option
                                      </button>
                                    </div>
                                  ))}

                                  <button
                                    onClick={() => addOption(questionIndex)}
                                    className="form-builder-button add-option-button"
                                  >
                                    Add Option
                                  </button>
                                </div>

                                <button
                                  onClick={() => removeQuestion(questionIndex)}
                                  className="form-builder-button remove-question-button"
                                >
                                  Remove Question
                                </button>
                              </div>
                            ))}

                            <button onClick={handleSubmit} className="form-builder-button save-form-button">
                              Update Form
                            </button>
                          </div>
                        ) : (
                          <div>

                            {form.questions.map((question, index) => (
                              <div key={index}>
                                <p>{question.label}</p>
                                <ul>
                                  {question.options.map((option, idx) => (
                                    <li key={idx}>{option}</li>
                                  ))}
                                </ul>
                              </div>
                            ))}
                            <button onClick={() => deleteForm(form._id)} className="form-builder-button delete-form-button">
                              Delete Form
                            </button>
                            <button onClick={() => editForm(form)} className="form-builder-button edit-form-button">
                              Edit Form
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <p>No forms available.</p>
              )}
            </div>
          )}
          {/* condtion i am applying */}
          {activeSection === 'uploadFile' && (
            <div style={{ marginLeft: '47px', marginRight: '30px' }}>
              <h2>Upload Attendance Excel</h2>
              <div {...getRootProps()} className="file-dropzone">
                <input {...getInputProps()} />
                <p>Drag and drop an Excel file here, or click to select a file</p>
              </div>
              <button onClick={handleUpload} className="form-builder-button save-form-button">
                Upload Excel
              </button>
            </div>
          )}
        </div>
      </div>

      <ToastContainer /> {/* Toast container for notifications */}
    </div>
    </>
  );
};

export default FormBuilder;