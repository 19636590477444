import React, { useState, useEffect } from "react";
import axios from "axios";
import "./LeaveHistory.css";
import { server } from "../api/apiservice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const LeaveHistory = ({ isOpen, toggLeaveHistory }) => {
  const [leaveHistory, setLeaveHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [cancelReason, setCancelReason] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedLeaveId, setSelectedLeaveId] = useState(null);

  const fetchLeaveHistory = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${server}leave/myLeaves`, {
        headers: { Authorization: `${token}` },
      });
      console.log("User Leave History Response:", response.data);
      setLeaveHistory(response.data);
    } catch (error) {
      console.error("Error fetching leave history:", error);
      setMessage("Failed to fetch leave history. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchLeaveHistory();
    }
  }, [isOpen]);
  useEffect(() => {
    fetchLeaveHistory();
  }, []);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  useEffect(() => {
    //  commanted the out side click close and open acttivites
    const handleClickOutside = (event) => {
      // const container = document.querySelector('.apply-leave-container');
      // if (container && !container.contains(event.target)) {
      //     toggLeaveHistory();
      // }
    };

    if (isOpen) {
      // document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      // document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const handleButtonClick = (event) => {
    event.preventDefault();
    toggLeaveHistory();
  };

  const handleCancelRequest = async (leaveId) => {
    if (!cancelReason.trim()) {
      setMessage("Please provide a reason for cancellation.");
      return;
    }

    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${server}leave/requestCancel/${leaveId}`,
        { reason: cancelReason },
        { headers: { Authorization: ` ${token}` } }
      );
      setMessage(response.data.message); // Success message
      setCancelReason(""); // Clear reason input
      setLeaveHistory((prevHistory) =>
        prevHistory.map((leave) =>
          leave._id === leaveId ? { ...leave, status: "Cancelled" } : leave
        )
      ); // Update the leave status to 'Cancelled'
      fetchLeaveHistory(); // Now this will work
    } catch (error) {
      console.error("Error requesting leave cancellation:", error);
      setMessage("Failed to request cancellation. Please try again.");
    }
  };

  const openCancelModal = (leaveId) => {
    setSelectedLeaveId(leaveId);
    setIsModalOpen(true);
  };

  const handleModalSubmit = () => {
    handleCancelRequest(selectedLeaveId);
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="Apply-button-container">
        <button
          className="Apply-button-click"
          onClick={handleButtonClick}
          style={{ border: "none" }}
        >
          {/* Add icon or text here if needed */}
        </button>
      </div>

      {isOpen && (
        <div className="history-overlay">
          <div className="leave-history-container apply-leave-container">
            <FontAwesomeIcon
              icon={faTimes}
              className="close-icon"
              onClick={toggLeaveHistory}
            />
            <h2>My Leave History</h2>
            {loading ? (
              <p className="loading-message">Loading...</p>
            ) : leaveHistory.length ? (
              <div className="table-wrapper">
                <table className="leave-history-table">
                  <thead>
                    <tr>
                      <th>Employee</th>
                      <th>Leave Type</th>
                      <th>Duration</th>
                      <th>Status</th>
                      <th>Applied Date</th>
                      <th>Comments</th>
                      <th> Cancel option</th>
                    </tr>
                  </thead>
                  <tbody>
                    {leaveHistory.map((leave) => (
                      <tr key={leave._id}>
                        <td>{leave.employee?.name || "N/A"}</td>
                        <td>{leave.leaveType}</td>
                        <td>{`${formatDate(
                          leave.leaveStartDate
                        )} - ${formatDate(leave.leaveEndDate)}`}</td>
                        <td>{leave.status}</td>
                        <td>{formatDate(leave.appliedDate)}</td>
                        <td
                          dangerouslySetInnerHTML={{
                            __html: leave.comments || "No comments",
                          }}
                        ></td>
                        <td>
                        {leave.status === "Approved" && !leave.cancelRequested && new Date() < new Date(leave.leaveEndDate) ? (
                            <button
                              onClick={() => openCancelModal(leave._id)}
                              style={{
                                backgroundColor: "#2c3e50",
                                color: "white",
                                padding: "5px 10px",
                                borderRadius: "6px",
                                border: "none",
                                cursor: "pointer",
                                fontSize: "14px",
                                marginRight: "10px",
                              }}
                            >
                              Cancel Leave
                            </button>
                          ) : (
                            <span
                              style={{ color: "#7f8c8d", fontSize: "10px" }}
                            >
                              No option 
                            </span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p>No leave history available.</p>
            )}
            {message && <p className="message">{message}</p>}
          </div>
        </div>
      )}

      {isModalOpen && (
        <div
          className="modal"
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#fff", // Dark gradient-like background
            borderRadius: "12px",
            zIndex: "1000",
            color: "white",
            width: "400px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            overflow: "hidden",
          }}
        >
          <div
            className="modal-header"
            style={{
              color: "#2c3e50",
              padding: "15px",
              textAlign: "center",
            }}
          >
            <h3 style={{ margin: "0", fontSize: "18px" }}>
              Cancel Leave Request
            </h3>
          </div>
          <div className="modal-content" style={{ padding: "20px" }}>
            <textarea
              value={cancelReason}
              onChange={(e) => setCancelReason(e.target.value)}
              placeholder="Please provide a reason for cancellation."
              style={{
                width: "100%",
                minHeight: "80px",
                fontSize: "14px",
                borderRadius: "8px",
                border: "1px solid #ddd",
                resize: "none",
                outline: "none",
                backgroundColor: "#f0f0f0",
              }}
            />
            <div className="modal-actions" style={{ marginTop: "20px" }}>
              <button
                onClick={handleModalSubmit}
                style={{
                  backgroundColor: "#2c3e50",
                  color: "white",
                  padding: "5px 10px",
                  borderRadius: "8px",
                  border: "none",
                  cursor: "pointer",
                  fontSize: "14px",
                  marginRight: "10px",
                }}
              >
                Submit
              </button>
              <button
                onClick={() => setIsModalOpen(false)}
                style={{
                  backgroundColor: "#e84545",
                  color: "white",
                  padding: "5px 10px",
                  borderRadius: "8px",
                  border: "none",
                  cursor: "pointer",
                  fontSize: "14px",
                }}
              >
                Cancal
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LeaveHistory;
