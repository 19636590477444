import React, { useState, useEffect } from "react";
import SelfRatingForm from "./SelfRatingForm";
import Profile from "./Profile";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Slider from "react-slick";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ScoreCircle from "../ScoreCircle/ScoreCircle";
import "./EmployeeDashboard.css";
import UserDetailCard from "../UserDetailCard/UserDetailCard";
import HolidayOverlay from "../Calender/HolidayOverlay";
import { server } from "../../api/apiservice";
import Notifications from "../Notifications";
import UpdateProfile from "../UpdateProfile";
import SidebarAll from "../sidebar";
// import Notifications from '../Notifications';
import MainDashboard from "../DashBoard/MainDashboard";
import ProfileData from "../EmpProfileDetails/ProfileData";
import EmployeeProfileDetails from "../EmpProfileDetails/EmployeeProfileDetails";
import ProfileUpdate from "../ProfileUpdate/ProfileUpdate";
import EmployeeDash from "../EmployeeDash/EmployeeDash";
import Graph from "../graph/Graph";
import EmployeeFeedback from "../feedback/EmployeeFeedback";
import NavigationBar from "../NavBar/NavigationBar"
import HierarchyChart from "../HierarchyChart/hierarchychart"
import AllUsersLeaveHistory from "../../leaveData/AllUsersLeaveHistory";

const EmployeeDashboard = () => {
  const [isRatingFormVisible, setIsRatingFormVisible] = useState(true);
  const [user, setUser] = useState(null);
  const [selectedForm, setSelectedForm] = useState("");
  const [users, setUsers] = useState([]);
  const [managerFeedback, setManagerFeedback] = useState([]); // State to store manager's feedback
  const [managerDetails, setManagerDetails] = useState({ name: "", role: "" }); // State for manager details
  const [ceoFeedback, setCeoFeedback] = useState([]); // State to store ceo's feedback
  const [ceoDetails, setCeoDetails] = useState({ name: "", role: "" }); // State for manager details
  const [selectedUser, setSelectedUser] = useState(null);
  const [isUserDetailVisible, setIsUserDetailVisible] = useState(false);
  const [overallAverageScore, setOverallAverageScore] = useState(0);
  const [employeeRatings, setEmployeeRatings] = useState([]);
  const [managerRatings, setManagerRatings] = useState([]);
  const [ceoRatings, setCeoRatings] = useState([]);
  const [averages, setAverages] = useState();
  const [peerFeedbacks, setPeerFeedbacks] = useState([]);
  const [overallAverage, setOverallAverage] = useState(null);
  const [topThreeUsers, setTopThreeUsers] = useState([]);
  // const [attendanceData, setAttendanceData] = useState([]);
  const [attendanceData, setAttendanceData] = useState({
    presentDays: 0,
    absentDays: 0,
    basicLeaves: 0,
  });
  const [isAttendanceVisible, setIsAttendanceVisible] = useState(false); // State to track attendance card visibility
  const [loading, setLoading] = useState(true);
  const [userRole, setUserRole] = useState("Employee");
  const [activeSection, setActiveSection] = useState("dashboard");
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const [selectedFeedbackUser, setSelectedFeedbackUser] = useState(null);
  const [feedback, setFeedback] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const getOrdinalSuffix = (index) => {
    const suffixes = ["st", "nd", "rd"];
    const remainder = index % 10;
    const suffix =
      remainder > 0 && remainder < 4 && ![11, 12, 13].includes(index)
        ? suffixes[remainder - 1]
        : "th";
    return `${index}${suffix}`;
  };

  const [holidays, setHolidays] = useState([
    { date: "26 Jan", name: "Republic Day ", imageUrl: "republic.jpg" },
    { date: "8 Mar", name: "Maha Shivaratri", imageUrl: "shivratri.avif" },
    { date: "25 Mar", name: "Holi", imageUrl: "holi.avif" },
    { date: "9 Apr", name: "Ugadi", imageUrl: "ugadi.webp" },
    { date: "1 May", name: "Labour Day ", imageUrl: "labour.jpeg" },
    { date: "15 Aug", name: "Independence Day", imageUrl: "indi.jpg" },
    { date: "7 Sep", name: "Ganesh Chaturthi", imageUrl: "ganesh.jpeg" },
    { date: "2 Oct", name: "Gandhi Jayanti", imageUrl: "gandhi.jpg" },
    { date: "11 Oct", name: "Maha Navami", imageUrl: "navami.avif" },
    { date: "12 Oct", name: "Dusshera", imageUrl: "dus.jpeg" },
    {
      date: "1 Nov",
      name: "Kannada Rajyothsava / Diwali",
      imageUrl: "kan.jpeg",
    },
    { date: "5 Nov", name: "Balipadyami, Deepavali", imageUrl: "diwali.jpeg" },
    { date: "25 Dec", name: "Christmas", imageUrl: "christmas.jpg" },
  ]);

  console.log("xxxxxxxxxxxxxx", ceoFeedback);
  const [isHolidayOverlayVisible, setIsHolidayOverlayVisible] = useState(false);
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);

  const navigate = useNavigate();
  console.log("12222222", overallAverage);

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    // Fetch attendance data when the component loads
    const fetchAttendance = async () => {
      try {
        const response = await axios.get(`${server}admin/attendance`, {
          headers: { Authorization: `${token}` },
        });
        const { presentDays, absentDays, basicLeaves } =
          response.data.data || {};
        setAttendanceData({
          presentDays: presentDays || 0,
          absentDays: absentDays || 0,
          basicLeaves: basicLeaves || 0,
        });
        // console.log('resppppppppp', response);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching attendance data:", error);
        setLoading(false);
      }
    };

    fetchAttendance();
  }, []);

  const toggleView = () => {
    setIsRatingFormVisible(!isRatingFormVisible);
  };

  const handleHolidaysClick = () => {
    setIsHolidayOverlayVisible(true);
  };

  const closeHolidayOverlay = () => {
    setIsHolidayOverlayVisible(false);
  };

  const handleDashboardClick = () => {
    setActiveSection("dashboard");
  };

  const handleEmpClick = () => {
    setActiveSection("Emp");
  };
  const handleProfileDetailsClick = () => {
    setActiveSection("profile");
  };

  const handleProfileUpdateClick = () => {
    setActiveSection("update");
  };

  const handleHierarchyChartClick = () => {
    setActiveSection("HierarchyChart");
  };
  const handlAllUsersLeaveHistorytClick = () => {
    setActiveSection("AllUsersLeaveHistory");
  };

  // Fetch all users on component mount
  useEffect(() => {
    const token = localStorage.getItem("token");
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${server}userRoutes/users`, {
          headers: { Authorization: `${token} ` },
        }); // Update the URL to your API endpoint
        setUsers(response.data); // Set the users data
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  // I am adding Fetch top 3 highest rated employees

  useEffect(() => {
    const fetchTopThreeUsers = async () => {
      try {
        const response = await axios.get(`${server}userRoutes/topThreeRatings`);
        // console.log("responseresponse", response.data.ratings[0]);
        // const newAverage =  response.data.ratings[0];
        // console.log("newAveragenewAveragenewAverage",newAverage.monthlyAverages[0]);
        // const newMonthlyAverage = newAverage.monthlyAverages[0];
        // console.log("newMonthlyAveragenewMonthlyAverage", newMonthlyAverage.average)

        setTopThreeUsers(response.data.ratings);
        // console.log('responseee.dataaa.ratings', response.data.ratings);
      } catch (error) {
        console.error("Error fetching top 3 rated users:", error);
      }
    };
    fetchTopThreeUsers();
  }, []);

  useEffect(() => {
    if (user && user._id) {
      const fetchPeerFeedback = async () => {
        try {
          const token = localStorage.getItem("token");
          const response = await axios.get(
            `${server}peer-feedback/${user._id}`,
            {
              headers: { Authorization: `${token}` },
            }
          );
          setPeerFeedbacks(response.data);
        } catch (error) {
          console.error("Error fetching peer feedback:", error);
        }
      };
      fetchPeerFeedback();
    }
  }, [user]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          navigate("/");
          return;
        }

        const { data } = await axios.get(`${server}auth/dashboard`, {
          headers: { Authorization: `${token} ` },
        });
        // console.log("iddddddddddddddddddd", data);

        setUser(data);

        const fetchEmployeeRatings = async () => {
          const response = await axios.get(
            `${server}manager-ratings/employee-ratings/${data._id}`,
            {
              headers: { Authorization: ` ${token} ` },
            }
          );

          const managerRatings = response.data.managerRatings.filter(
            (rating) => rating.employeeId && rating.employeeId._id === data.id
          );
             console.log("managerRatings1", response.data.managerRatings);
             console.log("managerRatings11", managerFeedback);
             console.log("managerRatings11", data._id);
             
          if (managerRatings.length > 0) {
            setManagerFeedback(
              managerRatings.map((rating) => ({
                feedback: rating.feedback,
                date: rating.createdAt,
                managerName: rating.managerId.name,
                managerRole: rating.managerId.role,
              }))
            );
            setManagerDetails({
              name: managerRatings[0].managerId.name,
              role: managerRatings[0].managerId.role,
            });
          } else {
            setManagerFeedback([]);
            setManagerDetails({ name: "", role: "" });
          }
        };

        fetchEmployeeRatings();
console.log("asdf");

        const fetchCeoFeedback = async () => {
          console.log("Starting fetchCeoFeedback function");
          
          try {
            const token = localStorage.getItem("token");
            console.log("Token:", token);
            console.log("Data ID:", data?._id);
            console.log("Server URL:", server);
            
            if (!token) {
              console.error("Token is missing");
              return;
            }

            const apiUrl = `${server}ceo-ratings/employee-ratings`;
            console.log("Making request to:", apiUrl);

            try {
              const response = await axios.get(
                apiUrl,
                {
                  headers: { 
                    Authorization: token,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                  },
                }
              );
              console.log("Raw API Response:", response.data.ceoRatings);
              const zzzz = response.data.ceoRatings;
              console.log("aaaaaaaz", zzzz);
              
              // console.log("ccccccccccccccccccc",zzzz);
              
              // setCeoFeedback(response.data.ceoRatings)
              setCeoFeedback(zzzz)  
            console.log("asasas", ceoFeedback);
              
              if (response.data && response.data.ceoRatings) {
                console.log("Processing CEO ratings data");
                const employeeRatings = response.data.ceoRatings.filter(
                  rating => rating.employeeId === data._id
                );
                
                const formattedFeedback = employeeRatings.map((rating) => ({
                  feedback: rating.feedback,
                  date: rating.createdAt,
                  employeeName: rating.employeeId?.name || "",
                  answers: rating.answers || [],
                }));

                console.log("Formatted feedback:", formattedFeedback);
                // setCeoFeedback(formattedFeedback);

                if (employeeRatings[0]?.employeeId) {
                  console.log("Setting CEO details");
                  setCeoDetails({
                    name: employeeRatings[0].employeeId.name || "CEO",
                    role: "CEO",
                  });
                }
              } else {
                console.log("No CEO ratings found in response");
              }
            } catch (axiosError) {
              console.error("Axios request failed:", {
                message: axiosError.message,
                status: axiosError.response?.status,
                statusText: axiosError.response?.statusText,
                responseData: axiosError.response?.data,
                requestURL: axiosError.config?.url,
                requestHeaders: axiosError.config?.headers
              });
            }
          } catch (error) {
            console.error("General error in fetchCeoFeedback:", error);
          }
        };

        fetchCeoFeedback();

        const fetchOverallAverage = async () => {
          try {
            const response = await axios.get(
              `${server}userRoutes/overall-average/${data._id}`,
              {
                headers: { Authorization: `${token} ` },
              }
            );
            // console.log(":3333333333", response);

            const monthlyAverage = response.data.average;
            setOverallAverage(monthlyAverage);
          } catch (error) {
            console.error("Error fetching overall average:", error);
          }
        };

        fetchOverallAverage();

        // toast.success('Successful!', {
        //   position: "top-right",
        //   autoClose: 2000, // Toast will auto close in 3 seconds
        // });
      } catch (error) {
        console.error("Error fetching user data:", error);
        navigate("/");
        // toast.error('Failed', {
        //   position: "top-right",
        //   autoClose: 2000,
        // });
      }
    };

    fetchUserData();
  }, [navigate]);

  const handleUserClick = (user) => {
    setSelectedFeedbackUser(user);
    setShowFeedbackForm(true);
  };

  const handleSignout = () => {
    // Remove any existing toasts first
    toast.dismiss();
    
    // Show the confirmation toast
    toast.info(
      <CustomLogoutToast />,
      {
        position: "top-right",
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        closeButton: false,
        // Ensure the toast appears on top of other content
        style: { zIndex: 9999 }
      }
    );
  };

  const CustomLogoutToast = ({ closeToast }) => (
    <div>
      <p style={{ color: "white" }}>Are you sure you want to Sign out?</p>
      <div style={{ display: "flex", justifyContent: "space-between", gap: "10px" }}>
        <button
          onClick={() => {
            // First clear the token
            localStorage.removeItem("token");
            
            // Dismiss the confirmation toast
            toast.dismiss();
            
            // Show success message
            toast.success("Successfully logged out!", {
              position: "top-right",
              autoClose: 2000,
              onClose: () => {
                // Navigate after the success toast closes
                navigate("/");
              }
            });
          }}
          style={{ 
            borderRadius: "20px", 
            cursor: "pointer",
            padding: "5px 15px"
          }}
        >
          Yes
        </button>
        <button
          onClick={() => toast.dismiss()}
          style={{ 
            borderRadius: "20px", 
            cursor: "pointer",
            padding: "5px 15px"
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );

  const handleFormSelect = (event) => {
    setSelectedForm(event.target.value);
  };

  // Helper function to capitalize the first letter
  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const handleAttendanceClick = () => {
    setIsAttendanceVisible(!isAttendanceVisible); // Toggle the attendance card visibility
  };

  const closeAttendanceModal = () => {
    setIsAttendanceVisible(false);
  };

  const handleSubmitFeedback = async () => {
    if (!feedback.trim()) {
      toast.error("Please enter feedback", {
        toastId: 'validation-error'  
      });
      return;
    }

    try {
      const token = localStorage.getItem("token");
      if (!token) throw new Error("Token is missing");

      // First make the API call
      await axios.post(
        `${server}peer-feedback/submit-feedback`,
        {
          receiverId: selectedFeedbackUser._id,
          feedback: feedback,
        },
        {
          headers: { Authorization: `${token}` },
        }
      );

      // Then update state
      setFeedback("");
      setSelectedFeedbackUser(null);
      
      // Close the form before showing toast
      setShowFeedbackForm(false);
      
      // Small delay before showing success toast
      setTimeout(() => {
        toast.success("Feedback submitted successfully!", {
          toastId: 'success-feedback',
          position: "top-right",
          autoClose: 3000
        });
      }, 100);

    } catch (error) {
      console.error("Error submitting feedback:", error);
      toast.error("Failed to submit feedback", {
        toastId: 'error-feedback',
        position: "top-right",
        autoClose: 3000
      });
    }
  };

  const handleFeedbackClick = () => {
    setActiveSection("feedback");
  };

  const handleFormSelection = (formId) => {
    navigate(`/form/${formId}`); // Ensure this is correct
  };

  if (!user) return <div>Loading...</div>;

  // Slider settings
  const sliderSettings = {
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true, // Enable center mode to focus on the current slide
    centerPadding: "0px",
    autoplay: true,
    autoplaySpeed: 2000,
  };
  const peerFeedbackSliderSettings = {
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    dots: true,
    centerMode: false,
  };
  // console.log("jhgfd", user.profileImage)

  return (
    <>
  <div className='emp-navbar'> <NavigationBar /></div>
    <div style={{width:"90%",top:"80px"}} className={`employee-dashboard-section ${isSidebarOpen ? 'sidebar-expanded' : ''}`}>
     
      <SidebarAll
        onUpdate={handleProfileUpdateClick}
        onProfile={handleProfileDetailsClick}
        onHierarchyChart={handleHierarchyChartClick}
        onDashboard={handleDashboardClick}
        onEmp={handleEmpClick}
        onSignout={handleSignout}
        onHolidays={handleHolidaysClick}
        onAttendance={handleAttendanceClick}
        onHistory={toggleView}
        onFeedback={handleFeedbackClick}
        userRole={userRole}
        onToggleSidebar={(isOpen) => setIsSidebarOpen(isOpen)}
      />
      
      {activeSection === "dashboard" && <MainDashboard  />}
      {activeSection === "profile" && <EmployeeProfileDetails />}
      {activeSection === "update" && <ProfileUpdate />}
      {activeSection === "HierarchyChart" && <HierarchyChart />}
      {activeSection === "AllUsersLeaveHistory" && <AllUsersLeaveHistory />}
      {activeSection === "feedback" && (
        <EmployeeFeedback
          token={localStorage.getItem("token")}
          userId={user._id}
        />
      )}
      <div>
        {activeSection === "Emp" && (
          <>
            <section className="employee-dashboard-section">
              <EmployeeDash />
              
              {/* <EmployeeFeedback token={localStorage.getItem("token")} userId={user._id}/> */}
              <div
                className="employee-dashboard"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "wrap",
                }}
              >
                <div
                  className="employee-dashboard-container"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                  }}
                >
                  {/* Attendance Modal */}
                  {isAttendanceVisible && (
                    <div className="attendance-modal-overlay">
                      <div className="attendance-modal">
                        <button
                          className="close-modal-btn"
                          onClick={closeAttendanceModal}
                        >
                          Close
                        </button>
                        <h2>Attendance Dashboard</h2>
                        <div className="main-integration">
                          <div className="integration">
                            <div className="integration-heading">
                              Present Days
                            </div>
                            <div className="integration-count">
                              {loading ? "..." : attendanceData.presentDays}
                            </div>
                          </div>
                          <div className="integration">
                            <div className="integration-heading">
                              Absent Days
                            </div>
                            <div className="integration-count">
                              {loading ? "..." : attendanceData.absentDays}
                            </div>
                          </div>
                          <div className="integration">
                            <div className="integration-heading">
                              Basic Leaves
                            </div>
                            <div className="integration-count">
                              {loading ? "..." : attendanceData.basicLeaves}
                            </div>
                          </div>
                        </div>
                        <table>
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>In Time</th>
                              <th>Out Time</th>
                              <th>Work Duration</th>
                              <th>Total Duration</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Array.isArray(attendanceData) &&
                            attendanceData.length > 0 ? (
                              attendanceData.map((attendance, index) => (
                                <tr key={index}>
                                  <td>
                                    {new Date(
                                      attendance.date
                                    ).toLocaleDateString()}
                                  </td>
                                  <td>{attendance.inTime}</td>
                                  <td>{attendance.outTime}</td>
                                  <td>{attendance.workDuration}</td>
                                  <td>{attendance.totalDuration}</td>
                                  <td>{attendance.status}</td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="6">
                                  No attendance data available
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                  <div className="hamburger-menu" onClick={toggleSidebar}>
                    <i className="fas fa-bars"></i>
                  </div>
                  <div className="content" style={{backgroundColor:"white"}}>
                    {isRatingFormVisible ? (
                      <SelfRatingForm selectedForm={selectedForm} />
                    ) : (
                      <Profile />
                    )}
                  </div>
                  <div className="feedback-container">
                    <div className="employee-dashboard-feedback-section">
                      {/* <h3>feedbacks</h3> */}
                      {/* CEO Feedback */}
                      <div className="ceo-feedback-section" >
                      <div
                        className="feedback-card ceo-feedback-card"
                      
                      >
                        <h3>CEO's Feedback</h3>
                        <p>{ceoDetails.name}</p>
                        {Array.isArray(ceoFeedback) && ceoFeedback.length > 0 ? (
                          <Slider {...peerFeedbackSliderSettings}>
                            {ceoFeedback.map((feedback, index) => (
                              <div key={index} className="peer-feedback-slide" style={{
                                height: "150px",
                                overflow: "auto"
                                
                              }}>
                                <div className="peer-feedback-content">
                                  <p className="feedback-text">{feedback.feedback || 'No feedback text'}</p>
                                
                                </div>
                              </div>
                            ))}
                          </Slider>
                        ) : (
                          <p>No feedback received yet</p>
                        )}
                      </div>
                      </div>
                      

                      {/* Manager and Peer Feedback Row */}
                      <div className="feedback-row">
                        <div className="feedback-card manager-feedback-card">
                          <h3>Manager's Feedback</h3>
                          <p>{managerDetails.name}</p>
                          {managerFeedback.length > 0 ? (
                            <Slider {...peerFeedbackSliderSettings}>
                              {managerFeedback.map((feedback, index) => (
                                <div
                                  key={index}
                                  className="peer-feedback-slide"
                                >
                                  <div className="peer-feedback-content">
                                    <p className="feedback-text">
                                      {feedback.feedback}
                                    </p>   
                                  </div>
                                </div>
                              ))}
                            </Slider>
                          ) : (
                            <p>No feedback received yet</p>
                          )}
                      
                        </div>
                        <div className="feedback-card peer-feedback-card" >
                          <h3>Peer's Feedback</h3>
                          <p>check peer's feedbacks</p>
                          {peerFeedbacks.length > 0 ? (
                            <Slider {...peerFeedbackSliderSettings}>
                              {peerFeedbacks.map((feedback, index) => (
                                <div
                                  key={index}
                                  className="peer-feedback-slide"
                                >
                                  <div className="peer-feedback-content">
                                    <p className="feedback-text">
                                      {feedback.feedback}
                                    </p>
                                    {/* <span className="feedback-date">
                          {new Date(feedback.createdAt).toLocaleDateString()}
                        </span> */}
                                  </div>
                                </div>
                              ))}
                            </Slider>
                          ) : (
                            <p>No feedback received yet</p>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* Right column with ratings */}
                    <div className="top-user-section">
                      <div className="top-user-rating">
                        <div className="feedback-and-rating-row">
                          <div className="average-rating-card">
                            <h3>Overall Average Rating</h3>
                            <ScoreCircle averageScore={overallAverage} />
                          </div>
                        </div>
                      </div>
                      <div className="top-user-graph">
                        {/* <button>Graph</button> */}
                        <Graph userId={user._id} />
                      </div>
                    </div>
                  </div>
                  <ToastContainer />
                </div>

                <div className="employee-dashboard-section-a">
                  {/* Top Three Employees - First Row */}
                  <div className="top-three-row" style={{height:"340px"}}>
                    <div
                      className="top-three-cccnnn"
                      style={{ ustifyContent: "center" }}
                    >
                      <h3
                        style={{
                          fontFamily: "Poppins,Sants",
                        }}
                      >
                        Top 3 Employees
                      </h3>
                      {topThreeUsers.map(
                        (user, index) => (
                          console.log("topThreeUsers", topThreeUsers),
                          (
                            <div
                              key={user._id}
                              className={`top-user-card top-user-${index + 1}`}
                            >
                              <img
                                src={
                                  user.profileImage
                                    ? `${server}${user.profileImage}`
                                    : "profilemen.avif"
                                }
                                alt={user.employeeName}
                                className="top-user-avatar"
                              />
                              <div className="top-user-details">
                                <p>
                                  <b>{user.employeeName}</b>
                                </p>
                                <p>
                                  <b>{getOrdinalSuffix(index + 1)}</b>
                                </p>
                              </div>
                            </div>
                          )
                        )
                      )}
                    </div>
                  </div>

                  {showFeedbackForm && (
                    <div className="feedback-form-overlay">
                      <div className="feedback-form">
                        <h3>Give Feedback to {selectedFeedbackUser?.name}</h3>
                        <textarea
                          value={feedback}
                          onChange={(e) => setFeedback(e.target.value)}
                          placeholder="Write your feedback here..."
                        />
                        <div className="feedback-form-buttons">
                          <button onClick={handleSubmitFeedback}>Submit</button>
                          <button
                            onClick={() => {
                              setShowFeedbackForm(false);
                              setFeedback("");
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  <div
                    className="user-slider"
                    style={{ alignContent: "center", paddingBottom:"10px",  height:"300px"}}
                  >
                    <h3 className="user-slider-name">
                      Send Peer's feedback
                      <span className="tooltip" style={{background:"#f7f1d2"}}>
                        Select employee & give anonymous feedback to your peers.
                      </span>
                    <p className="user-slider-name-">hover for more info 👆</p>

                    </h3>
                    {/* <p style={{ marginLeft: "60px", marginTop:"0" }}>Click to give anonymous feedback.</p> */}
                    <Slider {...sliderSettings}>
                      {users
                        .filter(sliderUser => sliderUser._id !== user._id)
                        .map((user, index) => (
                          <div
                            key={index}
                            className="user-card"
                            onClick={() => handleUserClick(user)}
                          >
                            <h4>{capitalizeFirstLetter(user.name)}</h4>
                            <p style={{ margin: "0 0 0 0px" }}>
                              {capitalizeFirstLetter(user.role)}
                            </p>
                          </div>
                        ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </section>
          </>
        )}
      </div>
    </div>
    </>
  );
};

export default EmployeeDashboard;
