import React, { useState } from "react";
import axios from "axios";
import { useNavigate, Link, useLocation } from "react-router-dom";
// import jwtDecode from 'jwt-decode';
import { jwtDecode } from "jwt-decode";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Login.css";
import { server } from "../../api/apiservice";
import { IoArrowBack } from "react-icons/io5";
const Login = ({ onSignupClick }) => {
  const [form, setForm] = useState({ email: "", password: "" });
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  // console.log("dfgdgfd", location.state);

  const handleChange = (e) =>
    setForm({ ...form, [e.target.name]: e.target.value });
  const handleForgotPasswordChange = (e) =>
    setForgotPasswordEmail(e.target.value);

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(`${server}auth/login`, form);
      localStorage.setItem("token", data.token);
      localStorage.setItem("userId", data.result._id);
      // console.log("tocken",data.token);
      console.log("zzzzzzzzzz1",data);
      console.log("zzzzzzzzzz2",data.result);
      console.log("zzzzzzzzzz3",data.result._id);
      
      

      localStorage.setItem("role", data.role);
      // console.log("rolerole",data.result.role);

      // console.log("rolerolerole",data.result.name);
      const userName = data.result.name;
      console.log("zzzzzzzzzzzzzzzz", userName);
      localStorage.setItem("userName", userName)

      const decodedToken = jwtDecode(data.token);
      const userRole = decodedToken.role;
      console.log("userRoleuserRole", userRole);

      localStorage.setItem("userRole", userRole);

      toast.success("Login successful!", {
        position: "top-right",
        autoClose: 2000,
      });

      setTimeout(() => {
        if (userRole === "admin") {
          navigate("/admin-dashboard");
        } else if (userRole.toLowerCase() === "manager") {
          navigate("/manager-dashboard");
        } else if (userRole.toLowerCase() === "ceo") {
          navigate("/ceo-dashboard");
        } else if (userRole.toLowerCase() === "hr") {
          const from = location.state?.from || '/hr-dashboard';
          navigate(from);
        } else {
          navigate("/employee-dashboard");
        }
      }, 2000);
    } catch (error) {
      console.error(error);
      toast.error("Login failed. Please check your email and password.", {
        position: "top-right",
        autoClose: 2000,
      });
    }
  };

  const handleForgotPasswordSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(`${server}userRoutes/forgotPassword`, {
        email: forgotPasswordEmail,
      });
      toast.success(data.message, {
        position: "top-right",
        autoClose: 2000,
      });
      setIsForgotPassword(false); // Go back to login form
    } catch (error) {
      console.error(error);
      toast.error(
        error.response?.data?.message || "An error occurred. Please try again.",
        {
          position: "top-right",
          autoClose: 2000,
        }
      );
    }
  };

  return (
    // <div className="login-container">
    //   <div className="login-box">
    //     {isForgotPassword ? (
    //       <>
    //         <h1>Forgot Password</h1>
    //         <form onSubmit={handleForgotPasswordSubmit}>
    //           <div className="input-group">
    //             <label htmlFor="forgotPasswordEmail">Email</label>
    //             <input
    //               type="email"
    //               id="forgotPasswordEmail"
    //               name="forgotPasswordEmail"
    //               value={forgotPasswordEmail}
    //               onChange={handleForgotPasswordChange}
    //               autoComplete="off"
    //               required
    //             />
    //           </div>
    //           <button type="submit" className="login-button">Submit</button> <br /><br />
    //           <button type="button" className="login-button" onClick={() => setIsForgotPassword(false)}>Back to Login</button>
    //         </form>
    //       </>
    //     ) : (
    //       <>
    //         <h1>Login</h1>
    //         <form onSubmit={handleLoginSubmit}>
    //           <div className="input-group">
    //             <label htmlFor="email">Email</label>
    //             <input
    //               type="email"
    //               id="email"
    //               name="email"
    //               value={form.email}
    //               onChange={handleChange}
    //               autoComplete="off"
    //               required
    //             />
    //           </div>
    //           <div className="input-groups">
    //             <label htmlFor="password">Password</label>
    //             <div className="password-container">
    //               <input
    //                 type={showPassword ? 'text' : 'password'}
    //                 id="password"
    //                 name="password"
    //                 value={form.password}
    //                 onChange={handleChange}
    //                 autoComplete="new-password"
    //                 required
    //               />
    //               <span
    //                 className="password-toggle"
    //                 onClick={() => setShowPassword(!showPassword)}
    //               >
    //                 {showPassword ? <FaEyeSlash /> : <FaEye />}
    //               </span>
    //             </div>
    //           </div>
    //           <div>
    //             <u style={{ color: "blue", cursor: "pointer" }} onClick={() => setIsForgotPassword(true)}>Forgot password?</u>
    //           </div><br />
    //           <button type="submit" className="login-button">Login</button>
    //         </form>
    //         {/* Signup link */}
    //         {/* <p>
    //         Don't have an account? <button onClick={onSignupClick} style={{ color: 'blue', background: 'none', border: 'none', cursor: 'pointer' }}>Signup</button>
    //       </p> */}
    //       </>
    //     )}
    //   </div>
    //   <ToastContainer />
    // </div>
    <div className="login-container">
      <div className="login-box">
        {isForgotPassword ? (
          <>
            <h1>Forgot Password</h1>
            <form onSubmit={handleForgotPasswordSubmit}>
              <div className="input-group">
                <label htmlFor="forgotPasswordEmail">Email</label>
                <input
                  type="email"
                  id="forgotPasswordEmail"
                  name="forgotPasswordEmail"
                  value={forgotPasswordEmail}
                  onChange={handleForgotPasswordChange}
                  autoComplete="off"
                  required
                  placeholder="Enter your email address"
                />
              </div>
              <button type="submit" className="login-button">
                Submit
              </button>
              <button
                type="button"
                className="secondary-button"
                onClick={() => setIsForgotPassword(false)}
              >
                <IoArrowBack /> Back to Login
              </button>
            </form>
          </>
        ) : (
          <>
            <h1>Login</h1>
            <form onSubmit={handleLoginSubmit}>
              <div className="input-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={form.email}
                  onChange={handleChange}
                  autoComplete="off"
                  placeholder="Email"
                  required
                />
              </div>
              <div className="input-group">
                <label htmlFor="password">Password</label>
                <div className="password-container">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    value={form.password}
                    onChange={handleChange}
                    autoComplete="new-password"
                    placeholder="Enter your password"
                    required
                  />
                  <span
                    className="password-toggle-Button"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>
              </div>
              <div className="forgot-password">
                <u onClick={() => setIsForgotPassword(true)}>
                  Forgot password?
                </u>
              </div>
              <button type="submit" className="login-button">
                Login
              </button>
            </form>
          </>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default Login;
