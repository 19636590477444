import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { server } from '../api/apiservice';
import { toast } from 'react-toastify';

const FormRatings = (props) => {
    const [forms, setForms] = useState([]);
    const [selectedFormId, setSelectedFormId] = useState(null);
    const [managerSelfRatings, setManagerSelfRatings] = useState([]);
    const [selectedRatingData, setSelectedRatingData] = useState(null);
    const [expandedManagerIds, setExpandedManagerIds] = useState([]);
    const [managerAnswers, setManagerAnswers] = useState({});
    const [managerFeedbacks, setManagerFeedbacks] = useState({});
    const [openManagerModal, setOpenManagerModal] = useState(false);
    const [hrSelfRatings, setHrSelfRatings] = useState([]);

    const { formId, managerSelfRatings: initialManagerRatings, onRatingSubmitted } = props;

    useEffect(() => {
        const fetchManagerData = async () => {
            const token = localStorage.getItem("token");
            if (!token) {
                console.error("No token found");
                return;
            }

            try {
                
                const formsResponse = await axios.get(`${server}admin/forms`, {
                    headers: { Authorization: `${token}` },
                });
                setForms(formsResponse.data);  
                if (formId) {
                    const ratingsResponse = await axios.get(
                        `${server}ceo-ratings/manager-self-ratings-by-form/${formId}`, 
                        {
                            headers: { Authorization: `${token}` },
                        }
                    );
                    setManagerSelfRatings(ratingsResponse.data);
                    setSelectedFormId(formId);
                }
            } catch (error) {
                if (error.response?.status === 401) {
                    toast.error("Session expired. Please login again.");
                } else {
                    console.error("Error fetching data:", error);
                    toast.error("Failed to load data");
                }
            }
        };

        fetchManagerData();
    }, [formId]);

    useEffect(() => {
        if (initialManagerRatings) {
            setManagerSelfRatings(initialManagerRatings);
        }
    }, [initialManagerRatings]);

    

    useEffect(() => {
        const fetchManagerSelfRatings = async () => {
            const token = localStorage.getItem("token");
            try {
                const response = await axios.get(`${server}ceo-ratings/managerself-rating`, {
                    headers: { Authorization: `${token}` }
                });
                if (response.data && Array.isArray(response.data.allSelfRatings)) {
                    setManagerSelfRatings(response.data.allSelfRatings);
                } else {
                    setManagerSelfRatings([]);
                }
            } catch (error) {
                console.error("Error fetching manager self-ratings:", error);
            }
        };
        fetchManagerSelfRatings();
    }, []);

    useEffect(() => {
        const fetchHrSelfRatings = async () => {
            const token = localStorage.getItem("token");
            try {
                const response = await axios.get(`${server}ceo-ratings/hr-selfratings`, {
                    headers: { Authorization: `${token}` }
                });
                if (response.data && Array.isArray(response.data)) {
                    setHrSelfRatings(response.data);
                } else {
                    setHrSelfRatings([]);
                }
            } catch (error) {
                console.error("Error fetching HR self-ratings:", error);
            }
        };
        fetchHrSelfRatings();
    }, []);

    const handleFormClick = async (formId) => {
        if (selectedFormId === formId) {
            setSelectedFormId(null);
            return;
        }

        setSelectedFormId(formId);
        const token = localStorage.getItem("token");
        try {
            const response = await axios.get(`${server}ceo-ratings/manager-self-ratings-by-form/${formId}`, {
                headers: { Authorization: `${token}` },
            });
            setManagerSelfRatings(response.data);
            setOpenManagerModal(false);
        } catch (error) {
            console.error("Error fetching manager self-ratings:", error);
        }
    };

    const handleRatingClick = async (employeeId) => {
        const token = localStorage.getItem("token");
        try {
            const response = await axios.get(`${server}ceo-ratings/ratings-by-user/${selectedFormId}/${employeeId}`, {
                headers: { Authorization: `${token}` },
            });
            setSelectedRatingData(response.data);
        } catch (error) {
            console.error("Error fetching rating data:", error);
        }
    };

    const handleManagerExpansion = async (managerId) => {
        setExpandedManagerIds(prevState =>
            prevState.includes(managerId)
                ? prevState.filter(id => id !== managerId)
                : [managerId]
        );
        setOpenManagerModal(true);
    };

    const handleManagerRatingChange = (question, value, managerId) => {
        setManagerAnswers(prevAnswers => ({
            ...prevAnswers,
            [managerId]: {
                ...prevAnswers[managerId],
                [question]: value,
            }
        }));
    };

    const handleManagerFeedbackChange = (managerId, value) => {
        setManagerFeedbacks(prevFeedbacks => ({
            ...prevFeedbacks,
            [managerId]: value
        }));
    };

    const handleManagerSubmit = async (selectedManagerId) => {
        const token = localStorage.getItem("token");
        try {
            const selectedForm = forms.find(form => form._id === selectedFormId);
            const feedback = managerFeedbacks[selectedManagerId] || '';

            const isOnlyFeedback = !managerAnswers[selectedManagerId] && feedback;

            if (isOnlyFeedback) {
                const defaultAnswer = {
                    questionIndex: 0,
                    response: "Feedback Only"
                };

                const feedbackData = {
                    managerId: selectedManagerId,
                    formId: selectedFormId || formId,
                    feedback: feedback,
                    answers: [defaultAnswer]
                };

                const response = await axios.post(
                    `${server}ceo-ratings/submit-manager-rating`,
                    feedbackData,
                    {
                        headers: { 
                            Authorization: `${token}`,
                            'Content-Type': 'application/json'
                        },
                    }
                );
                console.log('asdfgh',response)

                if (response.data.success) {
                    toast.success('Feedback submitted successfully!');
                    setSelectedFormId(null);
                    setManagerFeedbacks({});
                    if (onRatingSubmitted) {
                        onRatingSubmitted();
                    }
                }
            } else {
                const ceoRatings = selectedForm.questions.map((question, index) => ({
                    questionIndex: index,
                    response: managerAnswers[selectedManagerId]?.[question.label] || ''
                }));

                if (!ceoRatings.every(rating => rating.response !== '')) {
                    toast.error('Please fill all required fields');
                    return;
                }

                const ratingData = {
                    managerId: selectedManagerId,
                    formId: selectedFormId || formId,
                    answers: ceoRatings,
                    feedback: feedback
                };

                const response = await axios.post(
                    `${server}ceo-ratings/submit-manager-rating`,
                    ratingData,
                    {
                        headers: { 
                            Authorization: `${token}`,
                            'Content-Type': 'application/json'
                        },
                    }
                );

                if (response.data.success) {
                    toast.success('Rating and feedback submitted successfully!');
                    setSelectedFormId(null);
                    setManagerAnswers({});
                    setManagerFeedbacks({});
                    if (onRatingSubmitted) {
                        onRatingSubmitted();
                    }
                }
            }
        } catch (error) {
            console.error("Submit error:", error);
            if (error.response?.status === 403) {
                toast.warning('You have already submitted ratings for this manager');
            } else if (error.response?.status === 401) {
                toast.error('Session expired. Please login again.');
                // Optionally redirect to login page
            } else {
                toast.error('Submission failed. Please try again.');
            }
        }
    };

    const calculateOverallAverage = (managerId) => {
        const manager = managerSelfRatings.find(r => r.employeeId && r.employeeId._id === managerId);
        if (!manager) return 'N/A';
        
        const ratings = manager.ratings.map(r => parseInt(r.response));
        if (ratings.length === 0) return 'N/A';
        
        return (ratings.reduce((a, b) => a + b, 0) / ratings.length).toFixed(1);
    };

    const calculateManagerAverage = (managerId) => {
        // Similar to calculateOverallAverage but for manager self-ratings
    };

    const calculateCeoAverage = (managerId) => {
        // Similar calculation for CEO ratings
    };

    const getRowColor = (managerResponse, ceoResponse) => {
        if (!managerResponse || !ceoResponse) return '';
        
        const managerValue = parseInt(managerResponse);
        const ceoValue = parseInt(ceoResponse);
        
        if (managerValue > ceoValue) return '#ffcccb';
        if (managerValue < ceoValue) return '#90ee90';
        return '#f8f9fa';
    };

    return (
        <div>
            {selectedFormId && (
                <div>
                    <h2>Managers who rated themselves for this form:</h2>
                    <table className="rating-table">
                        <thead>
                            <tr>
                                <th>Sl No.</th>
                                <th>Name</th>
                                <th>Overall Avg</th>
                                <th>Action</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {managerSelfRatings.map((rating, index) => (
                                <tr key={rating.employeeId ? rating.employeeId._id : index}>
                                    <td>{index + 1}</td>
                                    <td>{rating.employeeId ? rating.employeeId.name : 'Unknown Employee'}</td>
                                    <td>{calculateOverallAverage(rating.employeeId?._id) || 'N/A'}</td>
                                    <td>
                                        <button
                                            onClick={() => handleManagerExpansion(rating.employeeId?._id)}
                                            style={{ background: "#2c3e50" }}
                                        >
                                            Open
                                        </button>
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                        {rating.ceoRating && (
                                            <span style={{ 
                                                color: '#2c3e50', 
                                                fontSize: '20px',
                                                fontWeight: 'bold'
                                            }}>
                                                ✓
                                            </span>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <h2>HR Ratings:</h2>
                    <table className="rating-table">
                        <thead>
                            <tr>
                                <th>Sl No.</th>
                                <th>Name</th>
                                <th>Overall Avg</th>
                                <th>Action</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {hrSelfRatings.map((rating, index) => (
                                <tr key={rating.employeeId ? rating.employeeId._id : index}>
                                    <td>{index + 1}</td>
                                    <td>{rating.employeeId ? rating.employeeId.name : 'Unknown Employee'}</td>
                                    <td>{calculateOverallAverage(rating.employeeId?._id) || 'N/A'}</td>
                                    <td>
                                        <button
                                            onClick={() => handleManagerExpansion(rating.employeeId?._id)}
                                            style={{ background: "#2c3e50" }}
                                        >
                                            Open
                                        </button>
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                        {rating.ceoRating && (
                                            <span style={{ 
                                                color: '#2c3e50', 
                                                fontSize: '20px',
                                                fontWeight: 'bold'
                                            }}>
                                                ✓
                                            </span>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    {openManagerModal && (
                        <div className="expanded-modal-overlay" onClick={() => setOpenManagerModal(false)}>
                            <div className="expanded-table-modal" onClick={e => e.stopPropagation()}>
                                <button 
                                    className=""
                                    onClick={() => setOpenManagerModal(false)}
                                >
                                    Close
                                </button>
                                {managerSelfRatings
                                    .filter(rating => rating.employeeId && expandedManagerIds.includes(rating.employeeId._id))
                                    .map(rating => (
                                        <div className="rating-details" key={rating.employeeId._id}>
                                            <h4>Ratings Summary for {rating.employeeId.name}</h4>
                                            <table className="ratings-table">
                                                <thead>
                                                    <tr>
                                                        <th>Manager's Self Ratings<br />Avg: {calculateManagerAverage(rating.employeeId._id) || 'N/A'}</th>
                                                        <th>CEO's Ratings<br />Avg: {calculateCeoAverage(rating.employeeId._id) || 'N/A'}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {rating.formId?.questions?.map((question, index) => {
                                                        const managerResponse = rating.ratings.find(r => r.questionIndex === index)?.response;
                                                        const ceoResponse = rating.ceoRating?.answers?.find(a => a.questionIndex === index)?.response;
                                                        return (
                                                            <tr key={index} style={{
                                                                backgroundColor: getRowColor(managerResponse, ceoResponse)
                                                            }}>
                                                                <td>{managerResponse || 'N/A'}</td>
                                                                <td>{ceoResponse || 'N/A'}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                            {!rating.ceoRating && (
                                                <>
                                                    <h3>Rate The Manager:</h3>
                                                    <div className="rating-form">
                                                        {forms
                                                            .find(f => f._id === selectedFormId)
                                                            ?.questions?.map((question, index) => (
                                                                <div key={index} className="rating-input">
                                                                    <label>{question.label}</label>
                                                                    <select
                                                                        onChange={(e) => handleManagerRatingChange(
                                                                            question.label,
                                                                            e.target.value,
                                                                            rating.employeeId._id
                                                                        )}
                                                                        value={managerAnswers[rating.employeeId._id]?.[question.label] || ''}
                                                                    >
                                                                        <option value="">Select an option</option>
                                                                        {question.options.map((option, i) => (
                                                                            <option key={i} value={option}>{option}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            ))
                                                        }

                                                        <div className="feedback-section">
                                                            <h4>Give Feedback</h4>
                                                            <textarea
                                                                value={managerFeedbacks[rating.employeeId._id] || ''}
                                                                onChange={(e) => handleManagerFeedbackChange(rating.employeeId._id, e.target.value)}
                                                                placeholder="Enter your feedback here"
                                                            />
                                                        </div>
                                                        
                                                        <button
                                                            className="submit-button"
                                                            onClick={() => handleManagerSubmit(rating.employeeId._id)}
                                                        >
                                                            Submit
                                                        </button>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    ))}
                            </div>
                        </div>
                    )}
                </div>
            )}

            {selectedRatingData && (
                <div>
                    <h2>Rating Details for {selectedRatingData.managerSelfRating.employeeId.name}</h2>
                    <table>
                        <thead>
                            <tr>
                                <th>Manager Self Rating</th>
                                <th>CEO Rating</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    {selectedRatingData.managerSelfRating.ratings.map((rating, index) => (
                                        <div key={index}>
                                            Question {rating.questionIndex}: {rating.response}
                                        </div>
                                    ))}
                                </td>
                                <td>
                                    {selectedRatingData.ceoRating ? (
                                        selectedRatingData.ceoRating.answers.map((answer, index) => (
                                            <div key={index}>
                                                Question {index + 1}: {answer.response}
                                            </div>
                                        ))
                                    ) : (
                                        <div>No CEO rating available</div>
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

FormRatings.defaultProps = {
    onRatingSubmitted: () => {} 
};

export default FormRatings;
