import React, { useState, useEffect } from "react";
// import SelfRatingForm from "./SelfRatingForm";
// import Profile from "./Profile";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Slider from "react-slick";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ScoreCircle from "../ScoreCircle/ScoreCircle";
import "./EmployeeDash.css";
import UserDetailCard from "../UserDetailCard/UserDetailCard";
import HolidayOverlay from "../Calender/HolidayOverlay";
import { server } from "../../api/apiservice";
import Notifications from "../Notifications";
import UpdateProfile from "../UpdateProfile";
import SidebarAll from "../sidebar";

function EmployeeDash() {
  const [isRatingFormVisible, setIsRatingFormVisible] = useState(true);
  const [user, setUser] = useState(null);
  const [selectedForm, setSelectedForm] = useState("");
  const [users, setUsers] = useState([]);
  const [managerFeedback, setManagerFeedback] = useState([]); // State to store manager's feedback
  const [managerDetails, setManagerDetails] = useState({ name: "", role: "" }); // State for manager details
  const [ceoFeedback, setCeoFeedback] = useState(""); // State to store ceo's feedback
  const [ceoDetails, setCeoDetails] = useState({ name: "", role: "" }); // State for manager details
  const [selectedUser, setSelectedUser] = useState(null);
  const [isUserDetailVisible, setIsUserDetailVisible] = useState(false);
  const [overallAverageScore, setOverallAverageScore] = useState(0);
  const [employeeRatings, setEmployeeRatings] = useState([]);
  const [managerRatings, setManagerRatings] = useState([]);
  const [ceoRatings, setCeoRatings] = useState([]);
  const [averages, setAverages] = useState();
  const [peerFeedbacks, setPeerFeedbacks] = useState([]);
  const [overallAverage, setOverallAverage] = useState(null);
  const [topThreeUsers, setTopThreeUsers] = useState([]);
  // const [attendanceData, setAttendanceData] = useState([]);
  const [userRole, setUserRole] = useState("Employee");
  const [attendanceData, setAttendanceData] = useState({
    presentDays: 0,
    absentDays: 0,
    basicLeaves: 0,
  });
  const [isAttendanceVisible, setIsAttendanceVisible] = useState(false); // State to track attendance card visibility
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  // const handleOpen=()=>{
  //   setIsOpen(!isOpen)
  // }

  const getOrdinalSuffix = (index) => {
    const suffixes = ["st", "nd", "rd"];
    const remainder = index % 10;
    const suffix =
      remainder > 0 && remainder < 4 && ![11, 12, 13].includes(index)
        ? suffixes[remainder - 1]
        : "th";
    return `${index}${suffix}`;
  };

  const [holidays, setHolidays] = useState([
    { date: "26 Jan", name: "Republic Day ", imageUrl: "republic.jpg" },
    { date: "8 Mar", name: "Maha Shivaratri", imageUrl: "shivratri.avif" },
    { date: "25 Mar", name: "Holi", imageUrl: "holi.avif" },
    { date: "9 Apr", name: "Ugadi", imageUrl: "ugadi.webp" },
    { date: "1 May", name: "Labour Day ", imageUrl: "labour.jpeg" },
    { date: "15 Aug", name: "Independence Day", imageUrl: "indi.jpg" },
    { date: "7 Sep", name: "Ganesh Chaturthi", imageUrl: "ganesh.jpeg" },
    { date: "2 Oct", name: "Gandhi Jayanti", imageUrl: "gandhi.jpg" },
    { date: "11 Oct", name: "Maha Navami", imageUrl: "navami.avif" },
    { date: "12 Oct", name: "Dusshera", imageUrl: "dus.jpeg" },
    {
      date: "1 Nov",
      name: "Kannada Rajyothsava / Diwali",
      imageUrl: "kan.jpeg",
    },
    { date: "5 Nov", name: "Balipadyami, Deepavali", imageUrl: "diwali.jpeg" },
    { date: "25 Dec", name: "Christmas", imageUrl: "christmas.jpg" },
  ]);

  const [isHolidayOverlayVisible, setIsHolidayOverlayVisible] = useState(false);
  // const [isSidebarVisible, setIsSidebarVisible] = useState(true);

  const navigate = useNavigate();

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    // Fetch attendance data when the component loads
    const fetchAttendance = async () => {
      try {
        const response = await axios.get(`${server}admin/attendance`, {
          headers: { Authorization: `${token}` },
        });
        const { presentDays, absentDays, basicLeaves } =
          response.data.data || {};
        setAttendanceData({
          presentDays: presentDays || 0,
          absentDays: absentDays || 0,
          basicLeaves: basicLeaves || 0,
        });
        // console.log('resppppppppp', response);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching attendance data:", error);
        setLoading(false);
      }
    };

    fetchAttendance();
  }, []);

  const toggleView = () => {
    setIsRatingFormVisible(!isRatingFormVisible);
  };

  const handleHolidaysClick = () => {
    setIsHolidayOverlayVisible(true);
  };

  const closeHolidayOverlay = () => {
    setIsHolidayOverlayVisible(false);
  };

  // Fetch all users on component mount
  useEffect(() => {
    const token = localStorage.getItem("token");
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${server}userRoutes/users`, {
          headers: { Authorization: `${token}` },
        }); // Update the URL to your API endpoint
        setUsers(response.data); // Set the users data
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  // I am adding Fetch top 3 highest rated employees

  useEffect(() => {
    const fetchTopThreeUsers = async () => {
      try {
        const response = await axios.get(`${server}userRoutes/topThreeRatings`);
       
        setTopThreeUsers(response.data.ratings);
        console.log("responseee.dataaa.ratings", response.data.ratings);
      } catch (error) {
        console.error("Error fetching top 3 rated users:", error);
      }
    };
    fetchTopThreeUsers();
  }, []);

  useEffect(() => {
    if (user && user._id) {
      const fetchPeerFeedback = async () => {
        try {
          const token = localStorage.getItem("token");
          const response = await axios.get(
            `${server}peer-feedback/${user._id}`,
            {
              headers: { Authorization: `${token}` },
            }
          );
          setPeerFeedbacks(response.data);
        } catch (error) {
          console.error("Error fetching peer feedback:", error);
        }
      };
      fetchPeerFeedback();
    }
  }, [user]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          navigate("/login");
          return;
        }

        const { data } = await axios.get(`${server}auth/dashboard`, {
          headers: { Authorization: `${token}` },
        });
        // console.log("iddddddddddddddddddd", data);

        setUser(data);

        const fetchEmployeeRatings = async () => {
          const response = await axios.get(
            `${server}manager-ratings/employee-ratings/${data._id}`,
            {
              headers: { Authorization: `${token}` },
            }
          );

          const managerRatingForEmployee = response.data.managerRatings.find(
            (rating) => rating.employeeId && rating.employeeId._id === data.id
          );

          // Set the feedback if it exists
          if (managerRatingForEmployee && managerRatingForEmployee.feedback) {
            setManagerFeedback(managerRatingForEmployee.feedback);
            setManagerDetails({
              name: managerRatingForEmployee.managerId.name,
              role: managerRatingForEmployee.managerId.role,
            });
          } else {
            setManagerFeedback("No feedback available yet.");
            setManagerDetails({ name: "", role: "" });
          }
        };

        fetchEmployeeRatings();

        const fetchCeoFeedback = async () => {
          const response = await axios.get(
            `${server}ceo-ratings/employee-ratings`,
            {
              headers: { Authorization: `${token}` },
            }
          );

          const ceoRatingForEmployee = response.data.ceoRatings.find(
            (rating) => rating.employeeId && rating.employeeId._id === data.id
          );

          // Set the feedback if it exists
          if (ceoRatingForEmployee && ceoRatingForEmployee.feedback) {
            setCeoFeedback(ceoRatingForEmployee.feedback);
            setCeoDetails({
              name: ceoRatingForEmployee.ceoId.name,
              role: ceoRatingForEmployee.ceoId.role,
            });
          } else {
            setCeoFeedback("No feedback available yet.");
            setCeoDetails({ name: "", role: "" });
          }
        };

        fetchCeoFeedback();

        const fetchOverallAverage = async () => {
          try {
            const response = await axios.get(
              `${server}userRoutes/overall-average/${data._id}`,
              {
                headers: { Authorization: `${token}` },
              }
            );
            // console.log(":3333333333", response);

            const monthlyAverage = response.data.average;
            setOverallAverage(monthlyAverage); // Setting the fetched average
          } catch (error) {
            console.error("Error fetching overall average:", error);
          }
        };

        fetchOverallAverage();

        // toast.success('Successful!', {
        //   position: "top-right",
        //   autoClose: 2000, // Toast will auto close in 3 seconds
        // });
      } catch (error) {
        console.error("Error fetching user data:", error);
        navigate("/login");
        // toast.error('Failed', {
        //   position: "top-right",
        //   autoClose: 2000,
        // });
      }
    };

    fetchUserData();
  }, [navigate]);

  const handleUserClick = (user) => {
    setSelectedUser(user);
    setIsUserDetailVisible(true);
  };

  const handelsignout = () => {};

  const handleSignout = () => {
    toast(<CustomLogoutToast />);
  };

  const CustomLogoutToast = ({ closeToast }) => (
    <div>
      <p style={{ color: "white" }}>Are you sure you want to Sign out?</p>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <button
          onClick={() => {
            localStorage.removeItem("token");
            toast.success("Successfully logged out!");
            navigate("/"); // navigate after logging out
            closeToast(); // close the toast
          }}
          style={{ borderRadius: "20px", cursor: "pointer" }}
        >
          Yes
        </button>
        <button
          onClick={closeToast}
          style={{ borderRadius: "20px", cursor: "pointer" }}
        >
          Cancel
        </button>
      </div>
    </div>
  );


  const handleFormSelect = (event) => {
    setSelectedForm(event.target.value);
  };

  // Helper function to capitalize the first letter
  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const handleAttendanceClick = () => {
    setIsAttendanceVisible(!isAttendanceVisible); // Toggle the attendance card visibility
  };

  const closeAttendanceModal = () => {
    setIsAttendanceVisible(false);
  };

  if (!user) return <div>Loading...</div>;

  // Slider settings
  const sliderSettings = {
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true, // Enable center mode to focus on the current slide
    centerPadding: "0px",
    autoplay: true,
    autoplaySpeed: 2000,
  };
  // console.log("jhgfd", user.profileImage)

  const handleProfileView = () => {
    if (user) {
      navigate("/profile-details", { state: { user } });
      // setShowProfileDetails(true)
    }
  };

  return (
    <div className="main-container">
      <div className="EmployeeDash-main-content">  
      
        <div className="my-collegues-list">
          {/* <h3 style={{ fontSize: "22px",marginTop: "10px", }}>MyWorkspace </h3> */}
          <h3 style={{ fontSize: "30px", marginTop: "10px",  letterSpacing: "1.5px",}}>
  MyWorkspace
</h3>

          <div className="pic-container"  >
            {users.map((user) => (
              <li key={user.id}>
                 <img
            style={{
              borderRadius: "50%",
              height: "50px",
              width: "50px",
              objectFit: "cover",
            }}
            className="profile-avatar-empDashboard"
            src={
              user.profileImage
                ? `${server}${user.profileImage}`
                : "pi.jpg"
            }
            alt="emp"/>
              </li>
            ))}
            </div>
          </div>
          
        </div>
      {/* </div> */}
    </div>
  );
}

export default EmployeeDash;
