import React, { useEffect, useState } from "react";
import axios from "axios";
import { server } from "../../api/apiservice";
import GaugeChart from 'react-gauge-chart';

const Graph = ({ userId }) => {
  const [monthlyAverages, setMonthlyAverages] = useState([]);
  const [percentageChange, setPercentageChange] = useState(0);
  const [overallRating, setOverallRating] = useState(0);
  const[averageStatus,setaverageStatus]=useState(0)
   console.log("monthlyAverages",monthlyAverages)
  
 
   useEffect(() => {
    const fetchOverallAverage = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${server}userRoutes/overall-averages-month/${userId}`,
          {
            headers: { Authorization: `${token}` },
          }
        );

        if (response.data && response.data.message === "Monthly averages retrieved successfully.") {
          // Get all ratings (self, manager, peer, CEO)
          const selfRating = response.data.selfRating || 0;
          const managerRating = response.data.managerRating || 0;
          const ceoRating = response.data.ceoRating || 0;

          // Calculate overall rating (average of all ratings)
          const totalRating = (selfRating + managerRating + ceoRating) / 3;
          
          // Convert to percentage (assuming max rating is 5)
          const ratingPercentage = (totalRating / 5) * 100;
          setOverallRating(ratingPercentage);
          console.log("ratingPercentage",totalRating)
          console.log("ratingPercentage",managerRating)
          console.log("ratingPercentage",ceoRating)
          console.log("selfRatingratingPercentage",selfRating)

          // Calculate monthly averages as before
          const currentMonthAverage = response.data.currentMonthAverage || 0;
          const lastMonthAverage = response.data.lastMonthAverage || 0;

          const normalizedCurrentMonth = Math.min(currentMonthAverage, 5);
          const normalizedLastMonth = Math.min(lastMonthAverage, 5);

          const currentMonthPercentage = (normalizedCurrentMonth / 5) * 100;
          const lastMonthPercentage = (normalizedLastMonth / 5) * 100;

          // Calculate total average dynamically
          const totalAverage = (lastMonthPercentage + currentMonthPercentage) / 2;
          setMonthlyAverages([
            { average: lastMonthPercentage },
            { average: currentMonthPercentage },
            { totalAverage } 
          ]);
          
          // Calculate percentage change
          if (normalizedCurrentMonth !== null && normalizedLastMonth !== null) {
            const change = ((normalizedCurrentMonth - normalizedLastMonth) / 5) * 100;

            
            setPercentageChange(isFinite(change) ? change : 0);
            console.log("change",change)
          }

          // New: Calculate average status percentage change
          const averageStatus = (lastMonthPercentage + currentMonthPercentage) / 2;
          console.log("Average Status Percentage Change:", averageStatus);
          setaverageStatus(averageStatus)
         
        }
      } catch (error) {
        console.error("Error fetching overall average:", error);
      }
    };

    if (userId) {
      fetchOverallAverage();
    }
  }, [userId]);

  if (monthlyAverages.length < 2) {
    return <p>Data not available</p>;
  }

  // Convert total average to decimal for gauge (e.g., 58.1% becomes 0.581)
  const gaugeValue = monthlyAverages[2]?.totalAverage / 100;

  return (
    <div className="graph-container">
      <h3>Performance Overview</h3>
      
      {/* Speedometer/Gauge Chart */}
      <div style={{ width: '180px', margin: '20px auto' }}>
        <GaugeChart
          id="gauge-chart"
          nrOfLevels={3}
          colors={["#FF5F6D", "#FFC371", "#2ECC71"]}
          arcWidth={0.3}
          percent={gaugeValue}
          textColor="#000000"
          formatTextValue={value => `${value.toFixed(1)}%`}
        />
      </div>

      {/* Rating Statistics */}
      <div className="statistics">
        {/* <p>Overall Rating: {overallRating.toFixed(1)}%</p> */}
        <div className={`performance-message ${percentageChange >= 0 ? 'positive' : 'negative'}`}>
          <p>
            Performance {percentageChange >= 0 ? 'increased' : 'decreased'} by{' '}
            <strong>{Math.abs(percentageChange).toFixed(1)}%</strong>
          </p>
          <p className="performance-status">
          Status: {averageStatus >= 70 ? 'Excellent' : overallRating >= 40 ? 'Good' : 'Needs Improvement'}
        </p>

        </div>
      </div>
    </div>
  );
};

export default Graph;

// compair monthlyAverages last month and this month and give the Performance incrised or decrised and how much